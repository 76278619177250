import axios from "axios";

const { REACT_APP_API_URL } = process.env;

function setUrl() {
  return `${REACT_APP_API_URL}/client/v1`;
}
let baseURL = setUrl();

const APIClient = axios.create({ baseURL });

function setAuthToken(token) {
  APIClient.defaults.headers["Cache-Control"] = "no-cache";
  APIClient.defaults.headers.Pragma = "no-cache";
  APIClient.defaults.headers.Authorization = `Bearer ${token}`;
}

function unsetAuthToken() {
  APIClient.defaults.headers.Authorization = "";
}

export { APIClient, setAuthToken, unsetAuthToken };
