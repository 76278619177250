import React, { Component } from "react";

import img1 from "../../../../assets/img/flyer.png";

import { NavLink } from "react-router-dom";
class ProductDetails extends Component {
  state = {
    order: [],
    data: {},
    variantValues: [],
    variantValuesName: [],
    attribute: "",
    attributeContainer: [],
    price: "",
    resultCount: 0,
  };

  componentDidMount() {
    //hello

    this.getAllProduct();
  }

  getAllProduct = async () => {};

  render() {
    // const token = localStorage.getItem("token");
    return (
      <main>
        <div className="main1-product-detail w-100 flex-j-c">
          <div className="main1-product-detail-dad w-90 flex-j-s">
            <div className="main1-product-detail-child1">
              <i className="mdi mdi-tune"></i>
              <strong>Filter</strong>
            </div>
            <div className="main1-product-detail-child-left w-40">
              <div className="main1-product-detail-child-left-img1">
                <img src={img1} alt="" />
              </div>
              <div className="flex m-t-20">
                <div className="main1-product-detail-child-left-img2">
                  <img src={img1} alt="" />
                </div>
              </div>
            </div>
            <div className="main1-product-detail-child-right w-80">
              <div className="main1-product-detail-child1-right-txt1">
                <a href="https://teleprint.at">Startseite</a>
                <span className="divider">/</span>
                <a href="https://teleprint.at/kategorie/Flyer/">Flyer</a>
              </div>
              <div className="main1-product-detail-child1-right-txt2">
                Flyer
              </div>
              {/* <div className="main1-product-detail-child1-right-txt3">
                Der Preis wird nach Anfrage angegeben und Sie werden
                benachrichtigt
              </div> */}
              <div className="main1-product-detail-child1-right-txt4"></div>

              <div className="main1-product-detail-btn1">
                <NavLink to="/buyFlyer">
                  <button>kaufen</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="main2-product-detail flex-j-c w-100">
          <div className="main2-product-detail-dad w-90">
            <div className="main2-product-detail-child1">
              <span>Beschreibung </span>
            </div>
            <div className="main2-product-detail-child2">
              Der Flyer (das Flugblatt) ist einer der beliebtesten Werbemittel.
              Das schnelle und einfache Austeilen der Flyer mit Ihrem
              individuellen Design ist einer der attraktivsten und
              kostengünstigsten Wege, um Werbung zu betreiben. Um mit Ihrem
              Flyer zu beeindrucken, muss sich der Flyer hochwertig anfühlen und
              der Inhalt sowie das Design Ihre Intention kurz und bündig
              wiedergeben können. Je nach Budget, kann von einer Vielzahl an
              Formaten und Größen ausgewählt werden und klassisch zwischen
              Schwarz/Weiß oder Farbe sowieso einseitigem und beidseitigem Druck
              wählen.
            </div>
          </div>
        </div>
        {/* <div className="main3-product-detail w-100 flex-j-c">
          <div className="main3-product-detail-dad w-90">
            <div className="main3-product-detail-txt">ÄHNLICHE PRODUKTE</div>
            <div className="flex-j-s">
              <div className="main3-product-detail-box">
                <img src={img3} alt="" />
                <div className="main2-home-txt1-pro">Blöcke</div>
                <div className="main2-home-txt2-pro">
                </div>
              </div>
              <div className="main3-product-detail-box">
                <img src={img4} alt="" />
                <div className="main2-home-txt1-pro">
                  Broschüren/ Zeitungen/ Magazine
                </div>
                <div className="main2-home-txt2-pro">
                </div>
              </div>
              <div className="main3-product-detail-box">
                <img src={img5} alt="" />
                <div className="main2-home-txt1-pro">Buchdruck</div>
                <div className="main2-home-txt2-pro">
                </div>
              </div>
              <div className="main3-product-detail-box">
                <img src={img6} alt="" />
                <div className="main2-home-txt1-pro">Bürodruck Sorten</div>
                <div className="main2-home-txt2-pro">
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </main>
    );
  }
}

export default ProductDetails;
