import * as type from "../action/actionTypes";
const initialState = {
  addCart: []
};
export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case type.ADD_CART:
      return { ...state, addCart: action.value };

    case type.REMOVE_CART:
      return { ...state, addCart: action.value };

    case type.GET_CART:
      return { ...state, addCart: action.value };

    case type.UBDATE_CART:
      return { addCart: action.value };
    default:
      return state;
  }
}
