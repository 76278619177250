import React, { Component, Fragment } from "react";
import { emit } from "jetemit";
import { Redirect } from "react-router-dom";

export default class gride extends Component {
  state = {
    show: false,
    redirect: false,
  };

  componentDidMount() {}

  handleToggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  handleLogOut = () => {
    emit("logOut");
    this.setState({ redirect: true });
  };
  render() {
    const { page } = this.props;
    const { redirect } = this.state;
    return (
      <Fragment>
        {redirect ? <Redirect to="/" /> : ""}
        <div>
          <ul className="main2-dashB-left-bottom-ul">
            <li className={`${page === "PROFILE" ? "dashB-li" : ""}`}>
              <span
                onClick={() => this.props.handleChangePage("PROFILE")}
                className="main2-dashB-left-bottom-a"
              >
                Konto-Details
              </span>
            </li>
            <li className={`${page === "ORDER" ? "dashB-li" : ""}`}>
              <span
                onClick={() => this.props.handleChangePage("ORDER")}
                className="main2-dashB-left-bottom-a"
              >
                Bestellungen
              </span>
            </li>

            <li id="middle2-3">
              <span
                onClick={this.handleLogOut}
                className="main2-dashB-left-bottom-a"
              >
                Abmelden
              </span>
            </li>
          </ul>
        </div>
      </Fragment>
    );
  }
}
