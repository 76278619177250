import React, { Component } from "react";
import { Fragment } from "react";
// api

// Components
import Cart1 from "../components/cart1";
import Cart2 from "../components/cart2";
import Cart3 from "../components/cart3";
import Sidebar from "../components/sidebar";
import { Redirect } from "react-router-dom";
import { Notification } from "rsuite";
export default class index extends Component {
  state = {
    uiPosition: [],
    cartData: [],
    Active: "step1",
    expireInSeconds: 180,
  };

  componentDidMount() {}

  goNext = (step) => {
    this.setState({ Active: step });
  };

  RedirectToSite = () => {
    this.setState({ redirect: true });
  };

  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div style={{ color: "black" }}>{message}</div>,
    });
  };

  getTotalPrice = (totalPrice) => {
    this.setState({ totalPrice });
  };

  getShibmentData = (shibmentData) => {
    this.setState({ shibmentData });
  };

  getCartData = (cartData) => {
    this.setState({ cartData });
  };

  render() {
    let { Active, totalPrice } = this.state;
    if (this.state.redirect) {
      return <Redirect to={`/`} />;
    }
    return (
      <Fragment>
        <div className="mainc1 w-100 flex-j-c">
          <div className="mainc1-b w-90 flex-j-s">
            <Sidebar totalPrice={totalPrice} />
            {Active === "step1" ? (
              <Cart1
                getTotalPrice={this.getTotalPrice}
                goNext={this.goNext}
                open={this.open}
              />
            ) : Active === "step2" ? (
              <Cart2
                goNext={this.goNext}
                data={this.state}
                redirect={this.RedirectToSite}
                expireInSeconds={this.state.expireInSeconds}
                openNotif={this.open}
                getShibmentData={this.getShibmentData}
                totalPrice={totalPrice}
              />
            ) : Active === "step3" ? (
              <Cart3
                goNext={this.goNext}
                data={this.state}
                redirect={this.RedirectToSite}
                expireInSeconds={this.state.expireInSeconds}
                open={this.open}
                shibmentData={this.state.shibmentData}
                cartData={this.state.cartData}
                totalPrice={totalPrice}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
