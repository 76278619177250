import React, { Component, Fragment } from "react";
import { Dropdown } from "rsuite";

import { order } from "../../../../api/Api";
import { setAuthToken } from "../../../../api/_api";
export default class gride extends Component {
  state = {
    shownStatus: "Status",
    show: false,
    status: [],
    data: [],
  };

  componentDidMount() {
    this.getStatus();
  }

  getOrder = async (statusName) => {
    try {
      const token =
        localStorage.getItem("token") && localStorage.getItem("token");
      // setAuthToken(token);
      const newValue = {
        statusName: statusName,
        phoneNumber: "09198698369",
        email: "m.kamalzadeh73@gmail.com",
      };
      const { data, status } = await order.getAllOrderWithStatus(newValue);
      if (status !== 200) {
        this.setState({ data: [] });
        return;
      }
      this.setState({ data: data.data });
      console.log("dataaa", data.data);
    } catch (error) {
      console.error(error);
    }
  };

  getStatus = async () => {
    const { data } = await order.getStatusOrder();
    console.log(data.data);
    this.setState({ status: data.data });
    this.getOrder(data.data[0]);
  };

  handleSelect = (status) => {
    this.setState({
      shownStatus: status,
    });
    this.getOrder(status);
  };

  render() {
    const { status, shownStatus, data } = this.state;
    return (
      <Fragment>
        <div className="main2-add-right min-height-60 flex-col">
          <table>
            <thead>
              <th className="best-table-num"> Bestellung </th>
              <th className="best-table-date"> Datum </th>
              <th className="best-table-status">
                <Dropdown title={shownStatus}>
                  {status.length
                    ? status.map((x) => {
                        return (
                          <Dropdown.Item onSelect={() => this.handleSelect(x)}>
                            {x}
                          </Dropdown.Item>
                        );
                      })
                    : ""}
                </Dropdown>
              </th>
              <th className="best-table-total"> Gesamtsumme </th>
              <th className="best-table-actions"> Aktionen </th>
            </thead>
            <tbody>
              {data.length ? (
                data.map((x) => {
                  return (
                    <tr>
                      <td>
                        <a href="https://teleprint.at/mein-konto/bestellung-anzeigen/837/">
                          {" "}
                          #837{" "}
                        </a>
                      </td>
                      <td>
                        <time datetime="2021-10-17T11:18:56+00:00">
                          {" "}
                          17/10/2021{" "}
                        </time>
                      </td>
                      <td data-title="Status"> In Bearbeitung </td>
                      <td data-title="Gesamtsumme">
                        <span className="best-table-total-span">
                          {" "}
                          € {x.tableId.finalPrice}{" "}
                        </span>
                        für {"  " + x.tableId.count + "  " + x.productName}
                      </td>
                      <td className="best-table-actions-td">
                        <a
                          className="best-table-actions-a"
                          href="https://teleprint.at/mein-konto/bestellung-anzeigen/837/"
                        >
                          Neu anordnen
                        </a>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div className="flex-j-c">
                  <div>there is no order</div>
                </div>
              )}
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}
