import React, { Component, Fragment } from "react";

import img1 from "../../../assets/img/Adresse(FAQ).png";
import img2 from "../../../assets/img/Öffnungszeiten(FAQ).png";
import img3 from "../../../assets/img/Kontakt(FAQ).png";
export default class gride extends Component {
  state = {
    show: false,
  };

  componentDidMount() {}

  handleToggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  render() {
    return (
      <Fragment>
        <main>
          <div className="main1-kontakt flex-j-c w-100">
            <div className="main1-kontakt-dad flex-col w-100">
              <div>
                <iframe
                  title="My Daily Marathon Tracker"
                  className="main1-kontakt-map"
                  frameborder="0"
                  allowfullscreen
                  aria-hidden="false"
                  tabindex="0"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2659.3566802409036!2d16.347488917709263!3d48.19974534843316
                        !3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x254c06a2d168d2fb!2sTELEprint%20Digitaldruck%20KG!5e0!3m2!1sde!2sat!4v15973
                        18064795!5m2!1sde!2sat"
                ></iframe>
              </div>
              <div className="main1-kontakt-dad2 flex-j-c w-100">
                <div className="main1-kontakt-dad2-box flex-j-c w-90 wrap">
                  <div
                    className="main1-FAQ-dad-middle1"
                    id="main1-FAQ-dad-middle1"
                  >
                    <div className="main1-FAQ-dad-middle1-img cursor-p">
                      <img src={img1} alt="" />
                    </div>
                    <div className="main1-FAQ-dad-middle1-txt1 cursor-p">
                      Adresse
                    </div>
                    <div className="main1-FAQ-dad-middle1-txt2 cursor-p">
                      <a
                        href="https://g.page/Teleprint-digitaldruck?share"
                        className="main1-FAQ-dad-middle1-txt2a"
                      >
                        TELEprint Digitaldruck KG
                      </a>
                    </div>
                    <div className="main1-FAQ-dad-middle1-txt3">
                      Westbahnstraße 9, 1070 Wien
                    </div>
                  </div>
                  <div
                    className="main1-FAQ-dad-middle1"
                    id="main1-FAQ-dad-middle1"
                  >
                    <div className="main1-FAQ-dad-middle1-img cursor-p">
                      <img src={img2} alt="" />
                    </div>
                    <div className="main1-FAQ-dad-middle1-txt1 cursor-p">
                      Öffnungszeiten
                    </div>
                    <div
                      className="main1-FAQ-dad-middle1-txt3"
                      id="main1-FAQ-dad-middle1-txt3"
                    >
                      Montag bis Freitag
                    </div>
                    <div className="main1-FAQ-dad-middle1-txt3">
                      09:00 – 18:00 Uhr
                    </div>
                  </div>
                  <div
                    className="main1-FAQ-dad-middle1"
                    id="main1-FAQ-dad-middle1"
                  >
                    <div className="main1-FAQ-dad-middle1-img cursor-p">
                      <img src={img3} alt="" />
                    </div>
                    <div className="main1-FAQ-dad-middle1-txt1 cursor-p">
                      Kontakt
                    </div>
                    <div className="main1-FAQ-dad-middle1-txt2 cursor-p">
                      <a
                        href="tel:++43 1 524 32 56"
                        className="main1-FAQ-dad-middle1-txt2a"
                      >
                        +43 1 524 32 56
                      </a>
                    </div>
                    <div>
                      <a
                        href="mailto:print@teleprint.at"
                        className="main1-FAQ-dad-middle1-txt2a"
                      >
                        print@teleprint.at
                      </a>
                    </div>
                  </div>
                  {/* <div className="main1-FAQ-dad-middle1">
                    <div className="main1-FAQ-dad-middle1-img cursor-p">
                      <img src={img4} alt="" />
                    </div>
                    <div className="main1-FAQ-dad-middle1-txt1 cursor-p">
                      Adresse
                    </div>
                    <div className="main1-FAQ-dad-middle1-txt2 cursor-p">
                      <a
                        href="https://teleprint.at/anfrage/"
                        className="main1-FAQ-dad-middle1-txt2a"
                      >
                        Gerne beantworten wir Ihre Anfragen.
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="main1-anfrage w-100 flex-j-c">
            <div
              className="main1-anfrage-dad w-90"
              id="main2-anfrage-dad-no-brdr"
            >
              <div className="main1-anfrage-child1">
                <div className="main1-anfrage-child1-txt1">Anfrage</div>
                <span></span>
                <div className="main1-anfrage-child1-txt2">
                  <span className=""></span> Pflichtfeleler
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="main2-anfrage w-100 flex-j-c">
            <div
              className="main2-anfrage-dad w-90 m-t-5"
              id="main2-anfrage-dad-no-brdr"
            >
              <div className="main2-anfrage-child2 w-50">
                <div className="main2-anfrage-txt1">Vorname</div>
                <div className="main2-anfrage-put">
                  <input type="text" />
                </div>
                <div className="main2-anfrage-txt1 m-t-20">
                  Email
                  <span>*</span>
                </div>
                <div className="main2-anfrage-put">
                  <input type="text" />
                </div>
              </div>
              <div className="main2-anfrage-child2 w-50">
                <div className="main2-anfrage-txt1">Nachname</div>
                <div className="main2-anfrage-put2">
                  <input type="text" />
                </div>
                <div className="main2-anfrage-txt1 m-t-20">Telefonnummer</div>
                <div className="main2-anfrage-put2">
                  <input type="text" />
                </div>
              </div>
            </div>
          </div>
          <div className="main2-anfrage w-100 flex-j-c">
            <div
              className="main2-anfrage-dad w-90"
              id="main2-anfrage-dad-no-brdr"
            >
              <div className="main2-anfrage-child2 w-100">
                <div className="main2-anfrage-txt1">
                  Nachricht
                  <span>*</span>
                </div>
                <textarea
                  className="main2-anfrage-txtarea w-100"
                  rows="10"
                  cols="50"
                ></textarea>
              </div>
            </div>
          </div> */}
          {/* <div className="w-100 flex-j-c">
            <div className="w-90 p-b-50">
              <div className="main2-anfrage-txt1">CAPTCHA</div>
              <div className="main2-anfrage-logo flex-j-s">
                <div className="flex w-60 m-t-24">
                  <div className="main2-anfrage-logo-child1"></div>
                  <div className="main2-anfrage-logo-child2">
                    Ich bin kein Roboter
                  </div>
                </div>
                <div className="main2-anfrage-right d-r">
                  <div className="main2-anfrage-right-img">
                    <img src="./img/blue-logo.png" alt="" />
                  </div>
                  <div className="main2-anfrage-right-txt1">reCAPTCHA</div>
                  <div className="main2-anfrage-right-txt2">
                    Privacy - Voorwaarden
                  </div>
                </div>
              </div>
              <div className="main2-anfrage-btn1 m-t-20">
                <button>ABSENDEN</button>
              </div>
            </div>
          </div> */}
        </main>
      </Fragment>
    );
  }
}
