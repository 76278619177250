import React, { Component, Fragment } from "react";

import img1 from "../../../../assets/img/flyer.png";
import {
  Form,
  FormGroup,
  Notification,
  SelectPicker,
  Button,
  ControlLabel,
  Progress,
} from "rsuite";
import ModalForm from "../../../../components/modal";
//api
import { Test } from "../../../../api/Api";
import { emit } from "jetemit";

export default class gride extends Component {
  state = {
    processing: false,
    progress: false,
    percent: 0,
    materialShow: false,
    src1: "",
    src2: "",
    cellophaneType: [
      {
        label: "Matt",
        value: "Matt",
      },
      { label: "glänzend", value: "glänzend" },
    ],
    shipment: [
      { label: "pickup", value: "pickup" },
      // {
      //   label: "send",
      //   value: "send",
      // },
    ],
    steps: "step1",

    doubleSided: [
      {
        label: "beidseitig",
        value: true,
      },
      { label: "einseitig", value: false },
    ],
    colorful: [
      {
        label: "S/W",
        value: false,
      },
      { label: "farbig", value: true },
    ],
    isCellophane: [
      {
        label: "Ja",
        value: true,
      },
      { label: "Nein", value: false },
    ],
    material: [],
    initialValues: {
      width: "",
      height: "",
      count: 1,
      isCellophane: false,
      colorful: false,
      doubleSided: false,
      description: "",
      customertype: "",
      firstName: "",
      lastName: "",
      postalCode: "",
      country: "",
      email: "",
      gender: "",
      address: "",
      street: "",
      phoneNumber: "",
      cellophaneType: "Matt",
      shipment: "pickup",
    },
    formError: {},
    formError1: {},
    loading: false,
    loading1: false,
    loadingRecord: false,
    show: false,
  };
  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div style={{ color: "black" }}>{message}</div>,
    });
  };

  componentDidMount() {}

  onChange = async (name, value) => {
    await this.setState({
      initialValues: {
        ...this.state.initialValues,
        [name]: value,
      },
    });
    this.getMaterial();

    if (name === "isCellophane") {
      await this.setState({ materialShow: true });
      this.getMaterial();
    }
  };

  loaderPercentage = (percent) => {
    let percentCompleted = Math.floor((percent.loaded / percent.total) * 100);
    this.setState({ percent: percentCompleted });
  };

  personalDataChecker = () => {
    const personalData = JSON.parse(localStorage.getItem("personalData"));
    if (!personalData || !personalData.phoneNumber) {
      this.setState({ openNotifHandleInfo: true });
      this.setState({ show: true });

      this.open("error", "error", "please enter your information");
      return { hasError: true, personalData: "" };
    } else {
      this.setState({ openNotifHandleInfo: false });
      return { hasError: false, personalData: personalData };
    }
  };
  handleSubmit = async () => {
    const { personalData } = this.personalDataChecker();

    await this.setState({
      initialValues: {
        ...this.state.initialValues,
        customertype: personalData.customertype,
        firstName: personalData.firstName,
        lastName: personalData.lastName,
        postalCode: personalData.postalCode,
        country: personalData.country,
        email: personalData.email,
        gender: personalData.gender,
        address: personalData.address,
        street: personalData.street,
        phoneNumber: personalData.phoneNumber,
      },
    });

    try {
      // if (await !this.form1.check()) {
      //   if (this.state.formError1.email) {
      //     this.open("error", "error", this.state.formError1.email);
      //   }
      //   if (this.state.formError1.phoneNumber) {
      //     this.open("error", "error", this.state.formError1.phoneNumber);
      //   }

      //   console.error("Form Error");
      //   return;
      // }

      this.setState({ loadingRecord: true, progress: true });
      const { data } = await Test.testRecord(
        this.state.initialValues,
        this.loaderPercentage
      );
      this.setState({ file1: "", file2: "" });
      // await localStorage.setItem(
      //   "phoneNumber",
      //   this.state.initialValues.phoneNumber
      // );
      // await localStorage.setItem("email", this.state.initialValues.email);
      emit("order", data);

      this.open(
        "success",
        "Bestätigung",
        "Dieser Artikel wurde Ihrem Einkaufswagen hinzugefügt."
      );
    } catch (error) {
      console.log(error);
      this.open("error", "error", "there is a problem");
    }
    this.setState({ loadingRecord: false, progress: false });
  };

  handleOpen = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    const {
      steps,

      progress,
      percent,
      show,
      shipment,
    } = this.state;

    return (
      <Fragment>
        <main>
          <ModalForm
            open={show}
            handleClose={this.handleClose}
            personalDataChecker={this.personalDataChecker}
          />
          {progress ? (
            <div className="loader-progress flex-j-c">
              <div className="percent-loader">
                <div
                  style={{
                    width: 120,
                    display: "inline-block",
                    marginRight: 10,
                  }}
                >
                  <Progress.Circle
                    percent={percent}
                    strokeColor="#f58634"
                    status={percent === 100 && "success"}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="main1-product-detail w-100 flex-j-c">
            <div className="main1-product-detail-dad w-90 flex-j-s">
              <div className="main1-product-detail-child1">
                <i className="mdi mdi-tune"></i>
                <strong>Filter</strong>
              </div>
              <div className="main1-product-detail-child-left w-40">
                <div className="main1-product-detail-child-left-img1">
                  <img src={img1} alt="" />
                </div>
                <div className="flex m-t-20">
                  <div className="main1-product-detail-child-left-img2">
                    <img src={img1} alt="" />
                  </div>
                  {/* <div className="main1-product-detail-child-left-img2-2">
                    <img src={img2} alt="" />
                  </div> */}
                </div>
              </div>
              <div className="main1-product-detail-child-right w-80">
                <div className="main1-product-detail-child1-right-txt1">
                  <a href="https://teleprint.at">Startseite</a>
                  <span className="divider">/</span>
                  <a href="https://teleprint.at/kategorie/Flyer/">Test</a>
                </div>
                <div className="main1-product-detail-child1-right-txt2">
                  Test
                </div>
                {/* <div className="main1-product-detail-icn-res flex">
                  <a
                    href="https://teleprint.at/produkte/Flyer-einfachfalz/"
                    rel="next"
                    className="button icon is-outline circle"
                  >
                    <i className="mdi mdi-less-than"></i>
                    <i className="mdi mdi-greater-than"></i>
                  </a>
                </div> */}
                {/* <div className="main1-product-detail-child1-right-txt3">
                  Der Preis wird nach Anfrage angegeben und Sie werden
                  benachrichtigt
                </div> */}

                <div className="main1-product-detail-child1-right-box1 flex-j-c">
                  <div className="w-95">
                    <>
                      <div className="flex-j-c ">
                        <div>
                          <Button
                            style={{
                              width: "200px",
                              padding: "10px",
                              margin: "15px",
                            }}
                            onClick={this.handleOpen}
                          >
                            information
                          </Button>
                          {this.state.openNotifHandleInfo ? (
                            <div style={{ color: "red" }}>
                              enter your information from hear
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <Form
                        onCheck={(formError1) => {
                          this.setState({ formError1 });
                        }}
                        ref={(ref) => (this.form1 = ref)}
                        formValue={this.state.initialValues}
                        layout="horizontal"
                        fluid
                      >
                        <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                          <ControlLabel>shipment:</ControlLabel>
                          <SelectPicker
                            defaultValue={shipment[0].value}
                            style={{ width: "80%" }}
                            searchable={false}
                            onChange={(e) => this.onChange("shipment", e)}
                            name="shipment"
                            className=""
                            data={shipment}
                            required
                            placeholder="shipment"
                            accepter={SelectPicker}
                          />
                        </FormGroup>
                      </Form>
                    </>
                  </div>
                </div>

                <div className="main1-product-detail-btn1 flex-j-s">
                  <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                    <Button
                      appearance="primary"
                      onClick={() => this.handleStep(1)}
                      disabled={steps === "step1"}
                    >
                      Vorherige Stufe
                    </Button>
                  </FormGroup>
                  <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                    <Button onClick={this.handleSubmit} appearance="primary">
                      Senden
                    </Button>
                  </FormGroup>
                </div>
                {/* <div className="main1-product-detail-btn1">
                  <NavLink to="/buyFlyer">
                    <button>kaufen</button>
                  </NavLink>
                </div> */}
              </div>
            </div>
          </div>
          <div className="main2-product-detail flex-j-c w-100">
            <div className="main2-product-detail-dad w-90">
              <div className="main2-product-detail-child1">
                <span>Beschreibung </span>
              </div>
              <div className="main2-product-detail-child2">
                Der Flyer (das Flugblatt) ist einer der beliebtesten
                Werbemittel. Das schnelle und einfache Austeilen der Flyer mit
                Ihrem individuellen Design ist einer der attraktivsten und
                kostengünstigsten Wege, um Werbung zu betreiben. Um mit Ihrem
                Flyer zu beeindrucken, muss sich der Flyer hochwertig anfühlen
                und der Inhalt sowie das Design Ihre Intention kurz und bündig
                wiedergeben können. Je nach Budget, kann von einer Vielzahl an
                Formaten und Größen ausgewählt werden und klassisch zwischen
                Schwarz/Weiß oder Farbe sowieso einseitigem und beidseitigem
                Druck wählen.
              </div>
            </div>
          </div>
          {/* <div className="main3-product-detail w-100 flex-j-c">
            <div className="main3-product-detail-dad w-90">
              <div className="main3-product-detail-txt">ÄHNLICHE PRODUKTE</div>
              <div className="flex-j-s">
                <div className="main3-product-detail-box">
                  <img src={img3} alt="" />
                  <div className="main2-home-txt1-pro">Blöcke</div>
                  <div className="main2-home-txt2-pro">
                  </div>
                </div>
                <div className="main3-product-detail-box">
                  <img src={img4} alt="" />
                  <div className="main2-home-txt1-pro">
                    Broschüren/ Zeitungen/ Magazine
                  </div>
                  <div className="main2-home-txt2-pro">
                  </div>
                </div>
                <div className="main3-product-detail-box">
                  <img src={img5} alt="" />
                  <div className="main2-home-txt1-pro">Buchdruck</div>
                  <div className="main2-home-txt2-pro">
                  </div>
                </div>
                <div className="main3-product-detail-box">
                  <img src={img6} alt="" />
                  <div className="main2-home-txt1-pro">Bürodruck Sorten</div>
                  <div className="main2-home-txt2-pro">
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </main>
      </Fragment>
    );
  }
}
