import React, { Component, Fragment } from "react";
import {
  Form,
  FormGroup,
  Notification,
  FormControl,
  Schema,
  // Loader,
  Progress,
  SelectPicker,
} from "rsuite";
import { Redirect } from "react-router-dom";
const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  // email: StringType()
  //   .isEmail("Please enter a valid email address.")
  //   .isRequired("This field is required."),
  firstName: StringType().isRequired("firstName is required."),
  lastName: StringType().isRequired("lastName is required."),
  email: StringType().isRequired("email is required."),
  phoneNumber: NumberType().isRequired("phoneNumber is required."),
});

const model_privateCustomer = Schema.Model({
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("This field is required."),
  // postalCode: NumberType("Please enter a valid number.").isRequired(
  //   "PLZ is required."
  // ),
  // country: StringType().isRequired("Land field is required."),
  // street: StringType().isRequired("Straße & Hausnummer field is required."),
  // address: StringType().isRequired("Ort field is required."),
  customertype: StringType().isRequired("Privat-/Geschäftskunde is required."),
  firstName: StringType().isRequired("Vorname is required."),
  lastName: StringType().isRequired("Nachname is required."),
  phoneNumber: StringType().isRequired("Telefonnummer is required."),
});
export default class gride extends Component {
  state = {
    show: false,
    showEdit: false,
    initialValue: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      gender: "",
    },

    gender: [
      {
        label: "Frau",
        value: "woman",
      },
      { label: "Herr", value: "Man" },
    ],
    country: [
      {
        label: "Österreich",
        value: "Österreich",
      },
      { label: "Belgien", value: "Belgien" },
      { label: "Bulgarien", value: "Bulgarien" },
      { label: "Dänemark", value: "Dänemark" },
      { label: "Deutschland", value: "Deutschland" },
      { label: "Estland", value: "Estland" },
      { label: "Frankreich", value: "Frankreich" },
      { label: "Griechenland", value: "Griechenland" },
      { label: "Irland", value: "Irland" },
      { label: "Italien", value: "Italien" },
      { label: "Kroatien", value: "Kroatien" },
      { label: "Liechtenstein", value: "Liechtenstein" },
      { label: "Luxemburg", value: "Luxemburg" },
      { label: "Niederlande", value: "Niederlande" },
      { label: "Polen", value: "Polen" },
      { label: "Rumänien", value: "Rumänien" },
      { label: "Schweden", value: "Schweden" },
      { label: "Slowakei", value: "Slowakei" },
    ],
    formError: {},
  };

  componentDidMount() {
    this.getData();
  }
  cleanContentTypeDetails = () => {
    console.log("formError", this.state.formError);
    this.setState({
      formError: {},
      initialValue: {
        ...this.state.initialValue,

        company: "",
        industry: "",
        UID_Nummer: "",
        Association: "",
        AssociationNumber: "",
        chairman: "",
        country:
          this.state.initialValue.customertype === "Partner"
            ? "Österreich"
            : this.state.initialValue.country,
      },
    });
  };
  getData = () => {
    const data = JSON.parse(localStorage.getItem("personalData"));

    if (data) {
      this.setState({
        initialValue: {
          ...this.state.initialValue,
          email: data.email,
          phoneNumber: data.phoneNumber,
          postalCode: data.postalCode,
          lastName: data.lastName,
          firstName: data.firstName,
          gender: data.gender,
        },
      });
    }
  };

  handleToggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div style={{ color: "black" }}>{message}</div>,
    });
  };

  handleSubmit = async () => {
    try {
      if (await !this.form.check()) {
        console.error("Form Error");
        return;
      }
      this.setState({ showEdit: false });
      this.open("success", "success", "data changed");
    } catch (error) {
      console.log(error);
    }
  };
  onChange = async (name, value) => {
    await this.setState({
      initialValue: { ...this.state.initialValue, [name]: value },
    });
  };
  render() {
    const {
      redirect,
      loadingButtom,
      progress,
      percent,
      showEdit,
      // gender,
      // country,
    } = this.state;
    return (
      <Fragment>
        {redirect ? <Redirect to="/" /> : ""}
        <main className="w-100 min-height-60">
          {progress ? (
            <div className="loader-progress flex-j-c">
              <div className="percent-loader">
                <div
                  style={{
                    width: 120,
                    display: "inline-block",
                    marginRight: 10,
                  }}
                >
                  <Progress.Circle
                    percent={percent}
                    strokeColor="#f58634"
                    status={percent === 100 && "success"}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="anfrage-form rs-form-control-wrapper">
            <Form
              onCheck={(formError) => {
                this.setState({ formError });
              }}
              ref={(ref) => (this.form = ref)}
              formValue={this.state.initialValue}
              model={model}
              layout="horizontal"
              fluid
            >
              <div className="main2-anfrage w-100 flex-j-c">
                <div className="main2-anfrage-dad w-90">
                  <div className="main2-anfrage-child2 w-50">
                    <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                      <div className="main2-anfrage-txt1">Email</div>

                      <div className="main2-anfrage-put">
                        <FormControl
                          className=""
                          placeholder="email"
                          disabled
                          name="email"
                          type="text"
                          required
                          onChange={(e) => this.onChange("email", e)}
                          style={{ width: "96%" }}
                        />
                      </div>
                    </FormGroup>

                    <div className="main2-anfrage-txt1 m-t-20">
                      Vorname
                      <span>*</span>
                    </div>
                    <div className="main2-anfrage-put">
                      <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                        <FormControl
                          className=""
                          disabled={!showEdit}
                          placeholder="Vorname"
                          name="firstName"
                          type="text"
                          required
                          onChange={(e) => this.onChange("firstName", e)}
                          style={{ width: "96%" }}
                        />{" "}
                      </FormGroup>
                    </div>
                  </div>
                  <div className="main2-anfrage-child2 w-50">
                    <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                      <div className="main2-anfrage-txt1">Telefonnummer</div>
                      <div className="main2-anfrage-put2">
                        <FormControl
                          className=""
                          placeholder="Telefonnummer"
                          name="phoneNumber"
                          disabled
                          type="text"
                          required
                          onChange={(e) => this.onChange("phoneNumber", e)}
                          style={{ width: "96%" }}
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                      <div className="main2-anfrage-txt1">
                        Nachname <span>*</span>
                      </div>
                      <div className="main2-anfrage-put2">
                        <FormControl
                          disabled={!showEdit}
                          className=""
                          placeholder="Nachname"
                          name="lastName"
                          type="text"
                          required
                          onChange={(e) => this.onChange("lastName", e)}
                          style={{ width: "96%" }}
                        />
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                  margin: "10px",
                }}
              >
                {showEdit ? (
                  !loadingButtom ? (
                    <>
                      {" "}
                      <button
                        type="submit"
                        style={{
                          padding: "10px 20px",
                          marginRight: "10px",
                        }}
                        onClick={this.handleSubmit}
                      >
                        Send
                      </button>{" "}
                      <button
                        type="button"
                        style={{
                          padding: "10px 20px",
                        }}
                        onClick={() => {
                          this.setState({ showEdit: false });
                          this.getData();
                        }}
                      >
                        cansle
                      </button>
                    </>
                  ) : (
                    "loading"
                  )
                ) : (
                  <>
                    <button
                      type="button"
                      style={{
                        padding: "10px 20px",
                      }}
                      onClick={() =>
                        this.setState({ showEdit: !this.state.showEdit })
                      }
                    >
                      Edit
                    </button>
                  </>
                )}
              </div>
            </Form>
          </div>
        </main>
      </Fragment>
    );
  }
}
