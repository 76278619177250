import React from "react";

import MenuLayout from "../../../components/Layouts/Menu_layout";
import Content from "./content/index";
// import Header from "../../components/Layouts/MainHeader";

function shop(props) {
  return (
    <>
      <Content props={props} />
    </>
  );
}

export default MenuLayout(shop);
