import React, { Component, Fragment } from "react";
import img1 from "../../../assets/img/Adresse(FAQ).png";
import img2 from "../../../assets/img/Öffnungszeiten(FAQ).png";
import img3 from "../../../assets/img/Kontakt(FAQ).png";

export default class gride extends Component {
  state = {
    show: false,
    faq: [
      {
        id: 1,
        text: " Welche Zahlungsmöglichkeiten bietet teleprint.at?",
        title: "EPS-ÜBERWEISUNG",
        answer:
          "eps-Überweisung ist ein einfaches und sicheres Online-Bezahlverfahren der österreichischen Banken für Einkäufe in Webshops oder für die Abwicklung elektronischer Amtsgeschäfte (E-Government) in Österreich.",
        open: true,
      },
      {
        id: 2,
        text: "Wann kann ich meinen Druckauftrag bezahlen?",
        answer: "جواب سوال",
        open: false,
      },
      {
        id: 3,
        text: " Wie storniere ich meine Bestellung?",
        answer: "جواب سوال",
        open: false,
      },
      {
        id: 4,
        text: "ضمانت بهترین قیمت به چه معناست؟",
        answer:
          "شما فقط از هر کد تخفیف یکبار میتوایند استفاده کنید. در هر سفارش فقط یک کد تخفیف میتوانید استفاده کنید. کد تخفیف برای کالاهای شگفت انگیز و کارت های هدیه دیجی کالا که در سایت دیجی کالا به بفروش میرسد ، قابل استفاده نیست.",
        open: false,
      },
      {
        id: 5,
        text: "برای پیگیری سفارشات قبلی وقتی ارسال شدن چیکار میتونم بکنم؟",
        answer: "جواب سوال",
        open: false,
      },
      {
        id: 6,
        text: "تحویل کالاها چندروزه است؟",
        answer: "جواب سوال",
        open: false,
      },
    ],
  };

  componentDidMount() {}

  handleClick = (id) => {
    this.setState({
      show: !this.state.show,
    });

    this.setState((state) => ({
      ...state,
      faq: state.faq.map((item) =>
        item.id === id
          ? { ...item, open: !item.open }
          : { ...item, open: false }
      ),
    }));
  };

  render() {
    return (
      <Fragment>
        <main>
          <div className="main1-FAQ flex-j-c w-100">
            <div className="main1-FAQ-dad flex-col w-90">
              <div className="main1-FAQ-dad-top t-c">Häufige Fragen (FAQ)</div>
              <div className="main1-FAQ-dad-top2"> </div>
              <div className="main1-FAQ-dad-middle flex-j-c wrap">
                <div
                  className="main1-FAQ-dad-middle1"
                  id="main1-FAQ-dad-middle1"
                >
                  <div className="main1-FAQ-dad-middle1-img cursor-p">
                    <img src={img1} alt="" />
                  </div>
                  <div className="main1-FAQ-dad-middle1-txt1 cursor-p">
                    Adresse
                  </div>
                  <div className="main1-FAQ-dad-middle1-txt2 cursor-p">
                    <a
                      href="https://g.page/Teleprint-digitaldruck?share"
                      className="main1-FAQ-dad-middle1-txt2a"
                    >
                      TELEprint Digitaldruck KG
                    </a>
                  </div>
                  <div className="main1-FAQ-dad-middle1-txt3">
                    Westbahnstraße 9, 1070 Wien
                  </div>
                </div>
                <div
                  className="main1-FAQ-dad-middle1"
                  id="main1-FAQ-dad-middle1"
                >
                  <div className="main1-FAQ-dad-middle1-img cursor-p">
                    <img src={img2} alt="" />
                  </div>
                  <div className="main1-FAQ-dad-middle1-txt1 cursor-p">
                    Öffnungszeiten
                  </div>
                  <div
                    className="main1-FAQ-dad-middle1-txt3"
                    id="main1-FAQ-dad-middle1-txt3"
                  >
                    Montag bis Freitag
                  </div>
                  <div className="main1-FAQ-dad-middle1-txt3">
                    09:00 – 18:00 Uhr
                  </div>
                </div>
                <div
                  className="main1-FAQ-dad-middle1"
                  id="main1-FAQ-dad-middle1"
                >
                  <div className="main1-FAQ-dad-middle1-img cursor-p">
                    <img src={img3} alt="" />
                  </div>
                  <div className="main1-FAQ-dad-middle1-txt1 cursor-p">
                    Kontakt
                  </div>
                  <div className="main1-FAQ-dad-middle1-txt2 cursor-p">
                    <a
                      href="tel:++43 1 524 32 56"
                      className="main1-FAQ-dad-middle1-txt2a"
                    >
                      +43 1 524 32 56
                    </a>
                  </div>
                  <div>
                    <a
                      href="mailto:print@teleprint.at"
                      className="main1-FAQ-dad-middle1-txt2a"
                    >
                      print@teleprint.at
                    </a>
                  </div>
                </div>
                {/* <div className="main1-FAQ-dad-middle1">
                  <div className="main1-FAQ-dad-middle1-img cursor-p">
                    <img src={img4} alt="" />
                  </div>
                  <div className="main1-FAQ-dad-middle1-txt1 cursor-p">
                    Adresse
                  </div>
                  <div className="main1-FAQ-dad-middle1-txt2 m-0-a w-90 cursor-p">
                    <a
                      href="https://teleprint.at/anfrage/"
                      className="main1-FAQ-dad-middle1-txt2a"
                    >
                      Gerne beantworten wir Ihre Anfragen.
                    </a>
                  </div>
                </div> */}
              </div>
              <div className="main1-FAQ-dad-bottom">
                <h3> Fragen zur Bestellung </h3>
                <div className="main1-FAQ-dad-bottom1">
                  <div className="main1-FAQ-dad-bottom1-1 cursor-p">
                    <button>
                      <i className="main1-FAQ-dad-bottom1-1-i mdi mdi-chevron-up"></i>
                    </button>
                    Welche Zahlungsmöglichkeiten bietet teleprint.at?
                  </div>
                  <div className="main1-FAQ-dad-bottom1-2">
                    <strong> EPS-ÜBERWEISUNG </strong> <br />
                    eps-Überweisung ist ein einfaches und sicheres
                    Online-Bezahlverfahren der österreichischen Banken für
                    Einkäufe in Webshops oder für die Abwicklung elektronischer
                    Amtsgeschäfte (E-Government) in Österreich.
                  </div>
                  <div className="main1-FAQ-dad-bottom1-3">
                    <strong> KREDITKARTE </strong> <br />
                    Zahlen Sie sicher und bequem per Visa oder MasterCard.
                    Direkt im Anschluss an Ihre Bestellung werden Sie zu unserem
                    Akzeptanzpartner weitergeleitet und können Ihre
                    Kreditkartendaten SSL-verschlüsselt eingeben. Die Belastung
                    Ihres Kreditkartenkontos erfolgt zum Zeitpunkt der Zahlung
                    (während der Bestellung oder im Kundenbereich).
                    Zahlungssicherheit durch 3-D Secure (Verified by Visa /
                    MasterCard SecureCode).
                  </div>
                </div>
                <div className="main1-FAQ-dad-bottom2 cursor-p">
                  <button>
                    <i className="main1-FAQ-dad-bottom2-i mdi mdi-chevron-down"></i>
                  </button>
                  Wann kann ich meinen Druckauftrag bezahlen?
                </div>
                <div className="main1-FAQ-dad-bottom2 cursor-p">
                  <button>
                    <i className="main1-FAQ-dad-bottom2-i mdi mdi-chevron-down"></i>
                  </button>
                  Wie storniere ich meine Bestellung?
                </div>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}
