import React, { Component, Fragment } from "react";

import notif from "../../../../utils/notif";

//redux
import { connect } from "react-redux";
import store from "../../../../store/store";
import { removeCart, addCart, reduce } from "../../../../action";

import { setAuthToken } from "../../../../api/_api";

//api
import { order } from "../../../../api/Api";

class Cart1 extends Component {
  state = {
    order: [],
    data: {},
    variantValues: [],
    variantValuesName: [],
    attribute: "",
    attributeContainer: [],
    price: "",
    resultCount: 0,
  };

  componentDidMount() {
    //hello
    this.unSub = store.subscribe(this.sub);

    this.getOrder();
  }

  unSub;
  sub = async () => {
    let reduxCart = store.getState();
    this.setState({ reduxCart });
  };

  componentWillUnmount() {
    this.unSub();
  }

  getOrder = async () => {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        setAuthToken(token);
        const { data, status } = await order.getOrder();
        if (
          (data.data &&
            data.data.orderItems &&
            data.data.orderItems.length === 0) ||
          (status && status === 204)
        ) {
          window.location.href = "/product";
        }

        if (status === 200) {
          let totalPrice = 0;
          data.data.orderItems.forEach((element) => {
            totalPrice += element.quantity * element.productPricing.price;
          });
          this.setState({ totalPrice });
          this.props.getTotalPrice(totalPrice);
          this.setState({ order: data.data.orderItems });
          this.props.getCartData(data.data.orderItems);
        } else if (status === 204) {
          this.setState({ order: [], totalPrice: 0 });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  reduce = (x) => {
    let token = localStorage.getItem("token");
    if (!token) {
      notif(" برای خرید محصول لطفا ابتدا لاگین کنید");
    } else this.props.Reduce(x);
  };

  addToCart = async (item, count) => {
    const token = localStorage.getItem("token");
    if (token) {
      setAuthToken(token);
      let newOrder = [...this.state.order];

      const cartIndex = newOrder.findIndex((x) => {
        return String(x.productPricingId) === String(item.id);
      });

      if (cartIndex === -1) {
        const object = {
          productPricingId: item.id,
          quantity: count,
        };
        newOrder.push(object);
      } else {
        newOrder[cartIndex].quantity = count;
      }

      // const { data } =
      await order.sendOrder(newOrder);
      this.getOrder();
    } else {
      notif(" برای خرید محصول لطفا ابتدا لاگین کنید");
    }
  };

  clearCart = async () => {
    try {
      // const data =
      await order.clearOrder();
      this.getOrder();
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    return (
      <Fragment>
        <div className="mcart1-r flex-j-c">
          <div className="mc1ur">
            <ul className="ulc1 flex-j-c">
              <li>
                <i className="mdi mdi-credit-card-outline"></i>
              </li>
              <li className="cdot1">........</li>
              <li>
                <i className="mdi mdi-map-marker-outline"></i>
              </li>
              <li className="cdot1">........</li>
              <li>
                <i className="mdi mdi-cart-variant" id="icnvariant"></i>
              </li>
            </ul>
            <ul className="ulc2 flex-j-s">
              <li onClick={() => this.props.goNext("step3")}>
                <p>انتخاب شیوه پرداخت </p>
              </li>
              <li onClick={() => this.props.goNext("step2")}>
                <p>انتخاب شیوه ارسال</p>
              </li>
              <li onClick={() => this.props.goNext("step1")}>
                <p id="sabadkharid">تکمیل سبد خرید</p>
              </li>
            </ul>

            <div className="mc1um flex-j-c">
              <button onClick={this.clearCart}>
                خالی کردن سبد خرید<i className="mdi mdi-delete-outline"></i>
              </button>
            </div>

            <div className="mc1ud flex-j-s">
              <div>
                <ul className="ulmc1ud flex-j-s">
                  <li>قیمت کل</li>
                  <li>تعداد</li>
                  <li>قیمت واحد</li>
                </ul>
              </div>
              <div className="mc1udright">نام کالا</div>
            </div>

            {this.state.order.map((x) => {
              return (
                <div className="mcart2 flex-j-s">
                  <div className="mcart2b flex-j-s">
                    <div className="mc2l1 d-r">
                      {x.quantity * x.productPricing.price}
                      <span>{x.productPricing.currency.name}</span>
                    </div>
                    <div className="mcart3 flex-j-c">
                      <ul className="ulmcart3 flex-j-c d-r">
                        <li
                          onClick={() =>
                            this.addToCart(
                              x.productPricing,

                              (x.quantity || 0) + 1
                            )
                          }
                          className="ulmc3l2u cursorP"
                        >
                          +
                        </li>
                        <li className="ulmc3l2m">{x.quantity}</li>
                        <li
                          onClick={() =>
                            this.addToCart(
                              x.productPricing,

                              (x.quantity || 0) - 1
                            )
                          }
                          className="ulmc3l2d cursorP"
                        >
                          -
                        </li>
                      </ul>
                    </div>
                    <div className="mc2l3 d-r">
                      {x.productPricing && x.productPricing.price}
                      <span>
                        {x.productPricing &&
                          x.productPricing.currency &&
                          x.productPricing.currency.name}
                      </span>
                    </div>
                  </div>
                  <div className="flex-j-s">
                    <div className="mc2l2">
                      {x.productPricing &&
                        x.productPricing.product &&
                        x.productPricing.product.name}
                    </div>
                    <div className="mc2r2">
                      <img
                        alt=""
                        src={
                          x.productPricing &&
                          x.productPricing.product &&
                          x.productPricing.product.images &&
                          x.productPricing.product.images[0] &&
                          x.productPricing.product.images[0].url
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="mcart5">
              <div className="mcart5-b flex-j-s">
                <div className="mcbut1">
                  <button onClick={() => this.props.goNext("step2")}>
                    <i className="mdi mdi-less-than"></i> انتخاب شیوه ارسال
                    سفارش
                  </button>
                </div>
                <div className="mctxt d-r">
                  <div className="mctxt1"> مبلغ قابل پرداخت</div>
                  <span className="mctxtsp">{this.state.totalPrice} </span>
                  <span className="mctxtsp1">ریال</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStatetoProps(state) {
  return { cart: state.data.addCart };
}

const mapDispatchToProps = (dispatch) => ({
  AddCart: function (value, price) {
    return dispatch(addCart(value, price));
  },
  RemoveCart: function (value) {
    return dispatch(removeCart(value));
  },
  Reduce: function (value) {
    return dispatch(reduce(value));
  },
  // UpdateCart: function (value) {
  //   return dispatch(updateCart(value));
  // },
});

export default connect(mapStatetoProps, mapDispatchToProps)(Cart1);
