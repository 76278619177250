import React, { Component } from "react";

import img1 from "../../../../assets/img/icons/Visitenkarten-Klappkarten.jpg";

import { NavLink } from "react-router-dom";
class ProductDetails extends Component {
  state = {
    order: [],
    data: {},
    variantValues: [],
    variantValuesName: [],
    attribute: "",
    attributeContainer: [],
    price: "",
    resultCount: 0,
  };

  componentDidMount() {
    //hello

    this.getAllProduct();
  }

  getAllProduct = async () => {};

  render() {
    // const token = localStorage.getItem("token");
    return (
      <main>
        <div className="main1-product-detail w-100 flex-j-c">
          <div className="main1-product-detail-dad w-90 flex-j-s">
            <div className="main1-product-detail-child1">
              <i className="mdi mdi-tune"></i>
              <strong>Filter</strong>
            </div>
            <div className="main1-product-detail-child-left w-40">
              <div className="main1-product-detail-child-left-img1">
                <img src={img1} alt="" />
              </div>
              <div className="flex m-t-20">
                <div className="main1-product-detail-child-left-img2">
                  <img src={img1} alt="" />
                </div>
                {/* <div className="main1-product-detail-child-left-img2-2">
                  <img src={img2} alt="" />
                </div> */}
              </div>
            </div>
            <div className="main1-product-detail-child-right w-80">
              <div className="main1-product-detail-child1-right-txt1">
                <a href="https://teleprint.at">Startseite</a>
                <span className="divider">/</span>
                <a href="https://teleprint.at/kategorie/Flyer/">
                  Visitenkarte_Klapp
                </a>
              </div>
              <div className="main1-product-detail-child1-right-txt2">
                Visitenkarte_Klapp
              </div>
              {/* <div className="main1-product-detail-icn-res flex">
                <a
                  href="https://teleprint.at/produkte/Flyer-einfachfalz/"
                  rel="next"
                  className="button icon is-outline circle"
                >
                  <i className="mdi mdi-less-than"></i>
                  <i className="mdi mdi-greater-than"></i>
                </a>
              </div> */}
              {/* <div className="main1-product-detail-child1-right-txt3">
                Der Preis wird nach Anfrage angegeben und Sie werden
                benachrichtigt
              </div> */}
              <div className="main1-product-detail-child1-right-txt4"></div>

              <div className="main1-product-detail-btn1">
                <NavLink to="/buyVisitenkarte_Klapp">
                  <button>kaufen</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="main2-product-detail flex-j-c w-100">
          <div className="main2-product-detail-dad w-90">
            <div className="main2-product-detail-child1">
              <span>Beschreibung </span>
            </div>
            <div className="main2-product-detail-child2">
              Auch in Zeiten des Computers und der digitalen Kommunikation,
              bleiben klassische Visitenkarten ein fester Bestandteil des
              Alltags. Das Teilen der wichtigsten Daten, E-Mail und
              Telefonnummer auf einer Visitenkarte nach einem kurzen Gespräch in
              der Bar oder auf einer Messe sind immer noch maßgeblich am
              erfolgreichen Vernetzen von Kontakten beteiligt. Teleprint bietet
              eine Vielzahl an Materialoptionen an, die von modernen
              Druckmaschinen und genauester Präzision bedruckt werden, um die
              perfekte Visitenkarte herzustellen. Falls Ihnen eine ein- oder
              beidseitig bedruckte Visitenkarte zu wenig ist, können Sie sich
              ebenfalls für eine Klappvisitenkarte (quadratische Karte)
              entscheiden.
              {/* <br />
              Per Foto erklären */}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ProductDetails;
