import React, { Component, Fragment } from "react";
import { Notification } from "rsuite";

export default class gride extends Component {
  state = {
    show: false,
    loading: false,
    initialValue: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      file: "",
      product: "",
      description: "",
    },
    formError: {},
  };

  componentDidMount() {}

  handleToggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  onChange = (name, value) => {
    this.setState({
      initialValue: {
        ...this.state.initialValue,
        [name]: value,
      },
    });
  };
  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div style={{ color: "black" }}>{message}</div>,
    });
  };

  handleChangefileOne = async ({ target }) => {
    this.setState({ loading: true });
    try {
      let file = target.files[0],
        src = target.value;
      var reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          initialValue: {
            ...this.state.initialValue,
            file: file,
          },
          file,
          imagePreviewUrl: reader.result,
        });
      };
      reader.readAsDataURL(file);

      if (target.files && target.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.setState({ image1: e.target.result });
        };
        reader.readAsDataURL(target.files[0]);
      }

      this.setState({ file, src1: src });
    } catch (error) {}
    setTimeout(() => {
      this.setState({ loading: false });
    }, 5000);
  };

  render() {
    return (
      <Fragment>
        <main>
          <div className="main1-impressum w-100 flex-j-c">
            <div className="main1-impressum-dad w-90">
              <div className="main1-impressum-txt1">Impressum</div>
              <div className="main1-impressum-brdr"></div>
              <div className="main1-impressum-txt2">
                Informationspflicht laut § 5 E-Commerce Gesetz, § 14
                Unternehmensgesetzbuch, § 63 Gewerbeordnung und
                Offenlegungspflicht laut § 25 Mediengesetz.
              </div>
              <div className="main1-impressum-txt3">
                TELEprint Digitaldruck KG
              </div>
              <div className="main1-impressum-txt4">
                A-1070 Wien, Westbahnstraße 9
              </div>
              <div className="main1-impressum-txt5">
                <strong>Unternehmensgegenstand: </strong>Digitaldruck & Copyshop
              </div>
              <div className="main1-impressum-txt5">
                <strong>UID-Nummer: </strong>ATU 7292 0226
              </div>
              <div className="main1-impressum-txt5">
                <strong>Firmenbuchnummer: </strong>479000m
              </div>
              <div className="main1-impressum-txt5">
                <strong>Firmenbuchgericht: </strong>Handelsgericht Wien
              </div>
              <div className="main1-impressum-txt5">
                <strong>Firmensitz: </strong>Wien
              </div>
              <div className="main1-impressum-txt5">
                <strong>Tel. </strong>01 5243256
              </div>
              <div className="main1-impressum-txt5">
                <strong>E-Mail: </strong>print@teleprint.at und (Administration:
                service@teleprint.at)
              </div>
              <div className="main1-impressum-txt5">
                <strong>Mitglied bei: </strong>WKO
              </div>
              <div className="main1-impressum-txt5">
                <strong>Aufsichtsbehörde/Gewerbebehörde: </strong>
                Magistratisches Bezirksamt des VII. Bezirkes
              </div>
              <div className="main1-impressum-txt5">
                <strong>Komplementär: </strong>Masoud Marvasti
              </div>
              <div className="main1-impressum-txt5">
                <strong>Bildernachweis </strong>{" "}
                <i>
                  {" "}
                  Die Bilder, Fotos und Grafiken auf dieser Webseite sind
                  urheberrechtlich geschützt.
                </i>
              </div>
              <div className="main1-impressum-txt5">
                <strong> Die Bilderrechte </strong>{" "}
                <i>
                  {" "}
                  liegen bei den folgenden Fotografen und Unternehmen: ishtar
                  Multiservice und Handels Gmbh
                </i>
              </div>
              {/* <div className="main1-impressum-txt6">
                <i>Freepik</i>
              </div> */}
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}
