import { createStore, combineReducers } from "redux";

// import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import cartReducer from "../reducer/cartReducer";
const reducers = {
  data: cartReducer
};
const store = createStore(
  combineReducers(reducers),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default store;
