/** @format */

import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import {
  Form,
  FormGroup,
  Notification,
  FormControl,
  Schema,
  // Loader,
  Progress,
} from "rsuite";
import { Support } from "../../../api/Api";
const { StringType, NumberType } = Schema.Types;
const model = Schema.Model({
  // email: StringType()
  //   .isEmail("Please enter a valid email address.")
  //   .isRequired("This field is required."),
  firstName: StringType().isRequired("firstName is required."),
  lastName: StringType().isRequired("lastName is required."),
  email: StringType().isRequired("email is required."),
  phoneNumber: NumberType().isRequired("phoneNumber is required."),
});
export default class gride extends Component {
  state = {
    show: false,
    loading: false,
    progress: false,
    percent: 0,
    initialValue: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      file1: "",
      file2: "",
      product: "",
      description: "",
    },
    formError: {},
    redirect: false,
    loadingButtom: false,
    loading2: false,
    loading1: false,
    src1: "",
    src2: "",
  };

  componentDidMount() {}

  handleToggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  onChange = (name, value) => {
    this.setState({
      initialValue: {
        ...this.state.initialValue,
        [name]: value,
      },
    });
    if (name === "description") {
      this.setState({
        initialValue: {
          ...this.state.initialValue,
          [name]: value.target.value,
        },
      });
    }
  };
  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div style={{ color: "black" }}>{message}</div>,
    });
  };

  loaderPercentage = (percent) => {
    let percentCompleted = Math.floor((percent.loaded / percent.total) * 100);
    this.setState({ percent: percentCompleted });
  };

  personalDataChecker = () => {
    const personalData = JSON.parse(localStorage.getItem("personalData"));
    if (!personalData || !personalData.phoneNumber) {
      this.setState({ openNotifHandleInfo: true });
      this.setState({ show: true });

      this.open("error", "error", "please enter your information");
      return { hasError: true, personalData: "" };
    } else {
      this.setState({ openNotifHandleInfo: false });
      return { hasError: false, personalData: personalData };
    }
  };
  handleSubmit = async () => {
    try {
      if (await !this.form.check()) {
        // if (this.state.formError.firstName) {
        //   this.open("error", "error", this.state.formError.firstName);
        // }
        // if (this.state.formError.lastName) {
        //   this.open("error", "error", this.state.formError.lastName);
        // }
        // if (this.state.formError.phoneNumber) {
        //   this.open("error", "error", this.state.formError.phoneNumber);
        // }
        // if (this.state.formError.email) {
        //   this.open("error", "error", this.state.formError.email);
        // }
        console.error("Form Error");
        return;
      }

      this.setState({ loadingButtom: true, progress: true });

      const { status } = await Support.Support(
        this.state.initialValue,
        this.loaderPercentage
      );
      if (status === 200) {
        this.open(
          "success",
          "Bestätigung",
          "Ihre Anfrage wurde erfolgreich gesendet."
        );
        this.setState({ redirect: true });
      }
    } catch (error) {
      console.error(error);
    }
    this.setState({ loadingButtom: false, progress: false });
  };

  handleChangefile = async ({ target }, Name) => {
    this.setState({ [`loading${Name}`]: true });
    try {
      let file = target.files[0],
        src = target.value;

      var reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          initialValue: {
            ...this.state.initialValue,
            [`file${Name}`]: file,
          },
          [`file${Name}`]: file,
          [`image${Name}`]: reader.result,
          [`src${Name}`]: src,
        });
      };
      reader.readAsDataURL(file);
    } catch (error) {}
    setTimeout(() => {
      this.setState({ [`loading${Name}`]: false });
    }, 5000);
  };

  render() {
    const { redirect, loadingButtom, progress, percent } = this.state;
    return (
      <Fragment>
        {redirect ? <Redirect to="/" /> : ""}
        <main>
          {progress ? (
            <div className="loader-progress flex-j-c">
              <div className="percent-loader">
                <div
                  style={{
                    width: 120,
                    display: "inline-block",
                    marginRight: 10,
                  }}
                >
                  <Progress.Circle
                    percent={percent}
                    strokeColor="#f58634"
                    status={percent === 100 && "success"}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="main1-anfrage w-100 flex-j-c">
            <div className="main1-anfrage-dad w-90">
              <div className="main1-anfrage-child1">
                <div className="main1-anfrage-child1-txt1">Anfrage</div>
                <span></span>
                <div className="main1-anfrage-child1-txt2">
                  {/* „<span className="">*</span>“  */}
                  {/* zeigt erforderliche Felder an */}
                  Pflichtfelder<span>*</span>
                </div>
                <div className="main1-anfrage-child1-txt3">
                  Ihre Kontaktdaten
                </div>
              </div>
            </div>
          </div>
          <div className="anfrage-form rs-form-control-wrapper">
            <Form
              onCheck={(formError) => {
                this.setState({ formError });
              }}
              ref={(ref) => (this.form = ref)}
              formValue={this.state.initialValue}
              model={model}
              layout="horizontal"
              fluid
            >
              <div className="main2-anfrage w-100 flex-j-c">
                <div className="main2-anfrage-dad w-90">
                  <div className="main2-anfrage-child2 w-50">
                    <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                      {/* <ControlLabel>Vorname:</ControlLabel>{" "} */}

                      <div className="main2-anfrage-txt1">
                        Vorname <span>*</span>
                      </div>

                      <div className="main2-anfrage-put">
                        <FormControl
                          className=""
                          placeholder="Vorname"
                          name="firstName"
                          type="text"
                          required
                          onChange={(e) => this.onChange("firstName", e)}
                          style={{ width: "96%" }}
                        />{" "}
                      </div>
                    </FormGroup>
                    {/* <div className="main2-anfrage-txt1">Vorname</div>
                <div className="main2-anfrage-put">
                  <input
                    required
                    name="firstName"
                    onChange={this.onChange}
                    type="text"
                  />
                </div> */}
                    <div
                      name="email"
                      onChange={this.onChange}
                      className="main2-anfrage-txt1 m-t-20"
                    >
                      Email
                      <span>*</span>
                    </div>
                    <div className="main2-anfrage-put">
                      {/* <input name="email" onChange={this.onChange} type="text" /> */}
                      <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                        <FormControl
                          className=""
                          placeholder="email"
                          name="email"
                          type="text"
                          required
                          onChange={(e) => this.onChange("email", e)}
                          style={{ width: "96%" }}
                        />
                      </FormGroup>
                    </div>
                    {/* <div className="main1-anfrage-child1-txt3">
                    Produktauswahl
                  </div> */}
                  </div>
                  <div className="main2-anfrage-child2 w-50">
                    <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                      <div className="main2-anfrage-txt1">
                        Nachname <span>*</span>
                      </div>
                      <div className="main2-anfrage-put2">
                        <FormControl
                          className=""
                          placeholder="Nachname"
                          name="lastName"
                          type="text"
                          required
                          onChange={(e) => this.onChange("lastName", e)}
                          style={{ width: "96%" }}
                        />
                      </div>
                    </FormGroup>
                    {/* <div className="main2-anfrage-txt1">Nachname</div>
                <div className="main2-anfrage-put2">
                  <input name="lastName" onChange={this.onChange} type="text" />
                </div> */}
                    {/* <div className="main2-anfrage-txt1 m-t-20">Telefonnummer</div>
                <div className="main2-anfrage-put2">
                  <input
                    name="phoneNumber"
                    onChange={this.onChange}
                    type="text"
                  />
                </div> */}
                    <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                      <div className="main2-anfrage-txt1">
                        Telefonnummer <span>*</span>
                      </div>
                      <div className="main2-anfrage-put2">
                        <FormControl
                          className=""
                          placeholder="Telefonnummer"
                          name="phoneNumber"
                          type="text"
                          required
                          onChange={(e) => this.onChange("phoneNumber", e)}
                          style={{ width: "96%" }}
                        />{" "}
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className="main2-anfrage w-100 flex-j-c">
                <div className="main2-anfrage-dad w-90">
                  <div className="main2-anfrage-child2 w-50">
                    <div className="main2-anfrage-txt1">Produkt</div>
                    <div className="main2-anfrage-put">
                      <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                        <FormControl
                          className=""
                          placeholder="product"
                          name="product"
                          type="text"
                          onChange={(e) => this.onChange("product", e)}
                          style={{ width: "96%" }}
                        />
                      </FormGroup>
                      {/* <select className="main2-anfrage-select">
                    <option
                      value=""
                      selected="selected"
                      className="gf_placeholder"
                    >
                      Bitte wählen
                    </option>
                    <option value="Aktenvernichten">Aktenvernichten</option>
                    <option value="Aufkleber">Aufkleber</option>
                    <option value="Backlight">Backlight</option>
                    <option value="Banner">Banner</option>
                    <option value="Blöcke">Blöcke</option>
                    <option value="Briefpapier">Briefpapier</option>
                    <option value="Broschüren">Broschüren</option>
                    <option value="Buttons">Buttons</option>
                    <option value="Bücher">Bücher</option>
                    <option value="Canvas">Canvas</option>
                    <option value="Displays">Displays</option>
                    <option value="Durchschreibegarnituren">
                      Durchschreibegarnituren
                    </option>
                    <option value="Etiketten">Etiketten</option>
                    <option value="Event-Set">Event-Set</option>
                    <option value="Flyer">Flyer</option>
                    <option value="Folder/Faltprospekte">
                      Folder/Faltprospekte
                    </option>
                    <option value="Grußkarten">Grußkarten</option>
                    <option value="Hinweistafeln">Hinweistafeln</option>
                    <option value="Kalender">Kalender</option>
                    <option value="Kapaplaten">Kapaplaten</option>
                    <option value="Keilrahmen">Keilrahmen</option>
                    <option value="Klebefolien">Klebefolien</option>
                    <option value="Kugelschreiber">Kugelschreiber</option>
                    <option value="Kuverts">Kuverts</option>
                    <option value="Kühlschrankmagnete">
                      Kühlschrankmagnete
                    </option>
                    <option value="Lesezeichen">Lesezeichen</option>
                    <option value="Lichtwerbung">Lichtwerbung</option>
                    <option value="Mousepads">Mousepads</option>
                    <option value="Notizblöcke">Notizblöcke</option>
                    <option value="Papierverkauf">Papierverkauf</option>
                    <option value="Plakate">Plakate</option>
                    <option value="Polster">Polster</option>
                    <option value="Poster">Poster</option>
                    <option value="Postkarten">Postkarten</option>
                    <option value="Rill &amp; Stanzprodukte">
                      Rill &amp; Stanzprodukte
                    </option>
                    <option value="Roll Ups">Roll Ups</option>
                    <option value="Schaumplatten">Schaumplatten</option>
                    <option value="Schreddern">Schreddern</option>
                    <option value="Spiralblöcke">Spiralblöcke</option>
                    <option value="Spiralmappen">Spiralmappen</option>
                    <option value="Stempel">Stempel</option>
                    <option value="Sticker">Sticker</option>
                    <option value="Stofftaschen">Stofftaschen</option>
                    <option value="T-Shirts">T-Shirts</option>
                    <option value="Tapeten">Tapeten</option>
                    <option value="Tassen">Tassen</option>
                    <option value="Textildrucke">Textildrucke</option>
                    <option value="Tischaufsteller">Tischaufsteller</option>
                    <option value="Tischdecken">Tischdecken</option>
                    <option value="Tischschilder">Tischschilder</option>
                    <option value="Tischsets">Tischsets</option>
                    <option value="Transparente">Transparente</option>
                    <option value="Trennblätter">Trennblätter</option>
                    <option value="Türhänger">Türhänger</option>
                    <option value="Verkaufsständer">Verkaufsständer</option>
                    <option value="Visitenkarten">Visitenkarten</option>
                    <option value="Zeitungen">Zeitungen</option>
                    <option value="Sonstiges">Sonstiges</option>
                  </select> */}
                    </div>
                  </div>
                  <div className="main2-anfrage-child2 w-50">
                    <div className="main2-anfrage-txt1">Anzahl</div>
                    <div className="main2-anfrage-put2">
                      <FormGroup className="marg-right20 head1-dad-down-right-inpt">
                        {/* <ControlLabel>Anzahl:</ControlLabel>{" "} */}
                        <FormControl
                          className=""
                          placeholder="Anzahl"
                          name="count"
                          type="number"
                          onChange={(e) => this.onChange("count", e)}
                          style={{ width: "96%" }}
                        />
                      </FormGroup>
                    </div>
                    {/* <div className="main2-anfrage-txt2">
                  Bitte gib eine Zahl größer oder gleich
                  <strong>0</strong>
                  ein.
                </div> */}
                  </div>
                </div>
              </div>
              <div className="main2-anfrage w-100 flex-j-c">
                <div
                  className="main2-anfrage-dad w-90"
                  id="main2-anfrage-dad-no-brdr"
                >
                  <div className="main2-anfrage-child2 w-50">
                    <div className="main2-anfrage-txt1">Beschreibung</div>
                    <textarea
                      name="description"
                      onChange={(e) => this.onChange("description", e)}
                      className="main2-anfrage-txtarea"
                      rows="10"
                      cols="50"
                    ></textarea>
                  </div>
                  <div className="main2-anfrage-child2 w-50">
                    <div className="main2-anfrage-txt1">
                      Datei
                      {/* (en)  */}
                      hochladen
                    </div>
                    <div className="main2-anfrage-box1 t-c">
                      <div className="main2-anfrage-txt3">
                        Ziehe Dateien hier her oder
                      </div>
                      <div
                        style={{ position: "relative" }}
                        className="main2-anfrage-btn1 t-c"
                      >
                        {" "}
                        <button type="button">
                          <div>
                            <label
                              style={{ zIndex: "999" }}
                              className="custom-file-upload main1-lab-bargozari-ax"
                            >
                              <div>
                                {/* {loading1 ? (
                                  <Loader center content="loading" />
                                ) : (
                                  ""
                                )} */}

                                {!this.state.image1 ? (
                                  <span>Wähle Dateien aus</span>
                                ) : (
                                  <i
                                    style={{ fontSize: "30px" }}
                                    className="mdi mdi-file-pdf"
                                  ></i>
                                )}

                                <input
                                  name="file1"
                                  type="file"
                                  // multiple
                                  id="file"
                                  required
                                  // accept="image/*"
                                  onChange={(e) => this.handleChangefile(e, 1)}
                                />
                              </div>
                            </label>
                          </div>
                        </button>
                        <button type="button">
                          <div>
                            <label
                              style={{ zIndex: "999" }}
                              className="custom-file-upload main1-lab-bargozari-ax"
                            >
                              <div>
                                {/* {loading2 ? (
                                  <Loader center content="loading" />
                                ) : (
                                  ""
                                )} */}

                                {!this.state.image2 ? (
                                  <span>Wähle Dateien aus</span>
                                ) : (
                                  <i
                                    style={{ fontSize: "30px" }}
                                    className="mdi mdi-file-pdf"
                                  ></i>
                                )}
                                <input
                                  name="file2"
                                  type="file"
                                  // multiple
                                  id="file"
                                  required
                                  // accept="image/*"
                                  onChange={(e) => this.handleChangefile(e, 2)}
                                />
                              </div>
                            </label>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div className="main2-anfrage-txt3">
                      Akzeptierte Dateiformat: PDF, JPG, PNG, TIFF. <br />
                      Maximale Dateigröße: 256 MB. <br />
                      Maximale Dateianzahl 1.
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                  margin: "10px",
                }}
              >
                {!loadingButtom ? (
                  <button
                    type="submit"
                    style={{
                      padding: "10px 20px",
                    }}
                    onClick={this.handleSubmit}
                  >
                    send
                  </button>
                ) : (
                  "loading"
                )}
              </div>
            </Form>
          </div>
          {/* <div className="w-100 flex-j-c">
            <div className="w-90 p-b-50">
              <div className="main2-anfrage-txt1">CAPTCHA</div>
              <div className="main2-anfrage-logo flex-j-s">
                <div className="flex w-60 m-t-24">
                  <div className="main2-anfrage-logo-child1"></div>
                  <div className="main2-anfrage-logo-child2">
                    ich bin kein Roboter
                  </div>
                </div>
                <div className="main2-anfrage-right d-r">
                  <div className="main2-anfrage-right-img">
                    <img src="./img/blue-logo.png" alt="" />
                  </div>
                  <div className="main2-anfrage-right-txt1">reCAPTCHA</div>
                  <div className="main2-anfrage-right-txt2">
                    Privacy - Voorwaarden
                  </div>
                </div>
              </div>
              <div className="main2-anfrage-btn1 m-t-20">
                <button>ABSENDEN</button>
              </div>
            </div>
          </div> */}
        </main>
      </Fragment>
    );
  }
}
