import React, { Component, Fragment } from "react";

export default class gride extends Component {
  state = {
    show: false,
  };

  componentDidMount() {}

  handleToggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  render() {
    return (
      <Fragment>
        <main>
          <div className="roles-main1 w-100 flex-j-c">
            <div className="roles-main1-baba flex-j-c">
              <div className="roles-main1-child1 d-r">
                <div className="roles-main1-txt1"> قوانین و مقررات</div>
                <div className="roles-main1-txt2">
                  <div className="roles-main1-txt2-child1">قوانین عمومی</div>
                  <div className="roles-main1-txt2-child2">
                    توجه داشته باشید کلیه اصول و رویه‏‌های دیجی‌کالا منطبق با
                    قوانین جمهوری اسلامی ایران، قانون تجارت الکترونیک و قانون
                    حمایت از حقوق مصرف کننده است و متعاقبا کاربر نیز موظف به
                    رعایت قوانین مرتبط با کاربر است. در صورتی که در قوانین
                    مندرج، رویه‏‌ها و سرویس‏‌های دیجی‌کالا تغییراتی در آینده
                    ایجاد شود، در همین صفحه منتشر و به روز رسانی می شود و شما
                    توافق می‏‌کنید که استفاده مستمر شما از سایت به معنی پذیرش
                    هرگونه تغییر است.
                  </div>
                </div>
                <div className="roles-main1-txt2">
                  <div className="roles-main1-txt2-child1">
                    تعریف مشتری یا کاربر
                  </div>
                  <div className="roles-main1-txt2-child2">
                    مشتری یا کاربر به شخصی گفته می‌شود که با اطلاعات کاربری خود
                    که در فرم ثبت نام درج کرده است، به ثبت سفارش یا هرگونه
                    استفاده از خدمات دیجی‌کالا اقدام نماید. <br /> همچنین از
                    آنجا که دیجی‌کالا یک وبسایت خرده‌فروشی است، طبق قانون تجارت
                    الکترونیک مشتری یا مصرف کننده هر شخصی است که به منظوری جز
                    تجارت یا شغل حرفه‌ای به خرید کالا یا خدمات اقدام می‌کند.
                  </div>
                </div>
                <div className="roles-main1-txt2">
                  <div className="roles-main1-txt2-child1">
                    تعریف فروشنده و شرایط کالاهای فروشندگان
                  </div>
                  <div className="roles-main1-txt2-child2">
                    {" "}
                    محصولات ارایه شده در وب سایت، یا توسط دیجی‌کالا از شرکت‌های
                    معتبر و واردکنندگان رسمی و قانونی خریداری و عرضه می‌شود و یا
                    برخی فروشندگان با دیجی‌کالا همکاری می‌کنند و محصولات خود را
                    به واسطه این فروشگاه اینترنتی، به صورت آنلاین به فروش
                    می‌رسانند. منظور از کالای فروشندگان، گروه دوم است و
                    فروشنده‌، شخصی حقیقی یا حقوقی است که محصول خود را در وب سایت
                    دیجی‌کالا عرضه کرده، به فروش می‌رساند.همچنین اعتبار
                    فروشنده‌ها توسط دیجی‌کالا بررسی و مدارک مورد نیاز از ایشان
                    اخذ می‌شود. <br /> - مسیولیت‌های مربوط به کیفیت، قیمت،
                    محتوا، شرایط و همچنین خدمات پس از فروش محصول بر عهده
                    فروشندگان است. <br /> - فاکتور کالاهایی که توسط فروشندگان در
                    سایت عرضه می‌شود، در صورت درخواست خریدار توسط فروشنده ارسال
                    می‌شود. <br /> - خریداران حداکثر تا ۲ روز بعد از ثبت سفارش و
                    قطعی شدن آن، فرصت دارند درخواست ارسال فاکتور را ثبت کنند.{" "}
                    <br /> - سفارش‌هایی که دارای حداقل یک کالا از فروشندگان
                    باشند و تا ساعت ۱۶ هر روز کاری نهایی شوند، حداقل ۱ روز کاری
                    بعد (طبق زمان تحویل اعلام شده در سایت) در تهران و شهرستان
                    ها، ارسال خواهد شد. <br /> - ۱ روز کاری فاصله زمانی تحویل
                    برای کالاهای فروشندگان، به معنای این است که در صورت خرید
                    کالای فروشندگان، شروع پردازش سفارش حداکثر ۱ روز پس از زمان
                    سفارش گذاری در دیجی‌کالا است. <br /> - منظور از ۱ روز کاری،
                    زمان آماده‌سازی و ارسال کالا به انبار دیجی‌کالا توسط فروشنده
                    است، که امکان دارد این زمان بسته به نوع کالا تغییر یابد.{" "}
                    <br /> - در صورت بروز تاخیر در پردازش سفارش شما، این موضوع
                    از طریق پیامک به شما اطلاع‌رسانی شده و دیجی‌کالا تلاش خواهد
                    کرد محصول/محصولات مورد نظر شما را ظرف ۲۴ ساعت تامین کند، در
                    صورت عدم تامین کالا بعد از ۲۴ ساعت، اقلام در انتظار تامین
                    سفارش شما به صورت سیستمی لغو خواهد شد. <br /> - در شرایط خاص
                    مثل فروش شگفت‌انگیز، احتمال لغو شدن سفارش مربوط به محصولات
                    فروشندگان به دلایلی مانند اتمام موجودی کالا وجود دارد و
                    دیجی‌کالا مجاز است بدون اطلاع قبلی نسبت به توقف سفارش گیری
                    جدید، اقدام و فروش را متوقف کند. <br /> - در صورتی که
                    دیجی‌کالا تخفیف به شرط خرید تا سقف خاصی را اعلام
                    کرد(Discount Voucher)،میزان خرید از کالاهای فروشندگان و
                    محاسبه نمی‌شود و این کالاها مشمول استفاده از این تخفیف‌ها
                    نمی‌شود. <br /> - نحوه برگشت از فروش کالاهای فروشندگان طبق
                    رویه دیجی‌کالا است، با این تفاوت که فروشندگان مسئولیت هرگونه
                    عدم مطابقت را به عهده می‌گیرد. <br /> - به کالاهای فروشندگان
                    تخفیف سازمانی تعلق نمی‌گیرد. <br /> - خدمات پس از فروش
                    دیجی‌کالا تنها در صورتی درخواست مشتری مبنی بر بازگرداندن
                    کالای فروشندگان را می‌پذیرد که لیبل بارکد نارنجی رنگ که روی
                    کالا نصب شده است، جدا نشده باشد.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}
