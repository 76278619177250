import React from "react";

import Content from "./content/index";
// import Header from "../../components/Layouts/MainHeader";

function auth(props) {
  return (
    <>
      <Content props={props} />
    </>
  );
}

export default auth;
