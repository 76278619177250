import React, { Component } from "react";

import img1 from "../../../../assets/img/folder8.jpg";

import { NavLink } from "react-router-dom";
class ProductDetails extends Component {
  state = {
    order: [],
    data: {},
    variantValues: [],
    variantValuesName: [],
    attribute: "",
    attributeContainer: [],
    price: "",
    resultCount: 0,
  };

  componentDidMount() {
    //hello

    this.getAllProduct();
  }

  getAllProduct = async () => {};

  render() {
    // const token = localStorage.getItem("token");
    return (
      <main>
        <div className="main1-product-detail w-100 flex-j-c">
          <div className="main1-product-detail-dad w-90 flex-j-s">
            <div className="main1-product-detail-child1">
              <i className="mdi mdi-tune"></i>
              <strong>Filter</strong>
            </div>
            <div className="main1-product-detail-child-left w-40">
              <div className="main1-product-detail-child-left-img1">
                <img src={img1} alt="" />
              </div>
              <div className="flex m-t-20">
                <div className="main1-product-detail-child-left-img2">
                  <img src={img1} alt="" />
                </div>
                {/* <div className="main1-product-detail-child-left-img2-2">
                  <img src={img2} alt="" />
                </div> */}
              </div>
            </div>
            <div className="main1-product-detail-child-right w-80">
              <div className="main1-product-detail-child1-right-txt1">
                <a href="https://teleprint.at">Startseite</a>
                <span className="divider">/</span>
                <a href="https://teleprint.at/kategorie/Folder/">Folder</a>
              </div>
              <div className="main1-product-detail-child1-right-txt2">
                Folder Wickel falz 8seiten
              </div>
              {/* <div className="main1-product-detail-icn-res flex">
                <a
                  href="https://teleprint.at/produkte/Folder-einfachfalz/"
                  rel="next"
                  className="button icon is-outline circle"
                >
                  <i className="mdi mdi-less-than"></i>
                  <i className="mdi mdi-greater-than"></i>
                </a>
              </div> */}
              {/* <div className="main1-product-detail-child1-right-txt3">
                Der Preis wird nach Anfrage angegeben und Sie werden
                benachrichtigt
              </div> */}
              <div className="main1-product-detail-child1-right-txt4"></div>

              <div className="main1-product-detail-btn1">
                <NavLink to="/buyFolderZfalz8Seiten">
                  <button>kaufen</button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="main2-product-detail flex-j-c w-100">
          <div className="main2-product-detail-dad w-90">
            <div className="main2-product-detail-child1">
              <span>Beschreibung </span>
            </div>
            <div className="main2-product-detail-child2">
              Folder sind ein beliebtes Mittel, um Informationen kurz und bündig
              an die Zielgruppe zu vermitteln. Folder eignen sich vor allem für
              Restaurants, Bars oder Events, als auch für persönliche
              Veranstaltungen wie Geburtstags- und Weihnachtsfeiern oder
              Hochzeiten, um über Veranstaltungszeiten zu informieren, die
              wichtigsten Produktinformationen näher zu bringen oder die
              Speisekarte besonders genüsslich darzustellen. Einfachfalz,
              Wickelfalz, Z-falz, Kreuzfalz, Fensterfalz Per Foto erklären
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default ProductDetails;
