import React, { Component, Fragment } from "react";
import { account } from "../../../../../api/Api";
import { NavLink, Redirect } from "react-router-dom";
import {
  Form,
  FormGroup,
  Notification,
  FormControl,
  Schema,
  // Loader,
} from "rsuite";
import Logo from "../../../../../assets/svg/logo";

const { StringType } = Schema.Types;
const model = Schema.Model({
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("Email is required."),

  verifyCode: StringType().isRequired("verifyCode is required."),
});
export default class StepOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expireInSeconds: 180,

      step: 1,
      initialValue: {
        email: "",
        verifyCode: "",
      },
      formError: {},
      redirect: false,
    };
  }

  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div style={{ color: "black" }}>{message}</div>,
    });
  };

  componentDidMount() {
    // const token = localStorage.getItem("token");
    // if (token) {
    //   this.setState({ redirect: true });
    // }
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  time = async () => {
    await this.setState({
      expireInSeconds: Number(this.state.expireInSeconds - 1),
    });
  };

  nextStep = (step) => {
    if (step === 2) {
      this.time();

      // set Interval
      this.interval = setInterval(this.time, 1000);
    }
    if (step === 1) {
      localStorage.removeItem("email");
    }
    this.setState({ step: step });
  };
  handleSubmitVerifyEmail = async () => {
    try {
      const { data, status } = await account.verify(this.state.initialValue);
      console.log(data, status);
      if (status === 200) {
        this.open("success", "success", "you can rigester now");

        this.props.goNext("step2");
      }
    } catch (e) {
      console.error(e);
      this.open("error", "error", "email is not confirmd");
    }
  };
  handleSubmitCheckEmail = async () => {
    try {
      const { data, status } = await account.checkEmail(
        this.state.initialValue
      );
      localStorage.setItem("email", this.state.initialValue.email);
      console.log(data, status);
      if (status === 226) {
        this.setState({ expireInSeconds: 180 });
        this.open(
          "success",
          "success",
          "you alredy have verifyCode use from that"
        );
        this.nextStep(2);
      }
      if (status === 200) {
        this.setState({ expireInSeconds: 180 });

        this.open("success", "success", "verify code has Sended");
        this.nextStep(2);
      }
    } catch (e) {
      console.error(e);
      this.open("error", "error", "verify code has not sended");
    }
  };

  onChange = (name, value) => {
    this.setState({
      initialValue: {
        ...this.state.initialValue,
        [name]: value,
      },
    });
  };
  render() {
    const { redirect, loadingButtom, step } = this.state;
    return (
      <Fragment>
        {redirect ? <Redirect to="/" /> : ""}
        <main>
          <div className="login-page w-100 flex-j-c">
            <div className="login-container w-100 flex-j-s">
              <div className="left-items w-50 flex-j-c">
                <div className="left-items--text-container">
                  <div className="left-items--title flex-j-c">
                    <span>
                      <NavLink to="/">
                        <Logo />
                      </NavLink>
                    </span>
                  </div>
                  <div className="left-items--content">
                    Welcome to TELEPRINT!
                  </div>
                </div>
              </div>
              <div className="right-items w-50 flex-j-c">
                <div className="right-items--container">
                  <div className="login-account-icon flex-j-c w-100">
                    <i className="mdi mdi-account"></i>
                  </div>
                  {step === 2 ? (
                    <div className="hcode flex-j-s">
                      <div className="cdleft p-r">
                        <i className="mdi mdi-timer-outline"></i>
                        <p>
                          <p className="font14 regular margleft-4 p blue">
                            {this.state.expireInSeconds}
                          </p>
                        </p>
                      </div>
                      {this.state.expireInSeconds === 0 ? (
                        <div className="cdright">
                          "resend code"
                          <i className="mdi mdi-reload"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    <div className="right-items--text">
                      Please Enter your Email to confirmed
                    </div>
                  )}

                  <Form
                    onCheck={(formError) => {
                      this.setState({ formError });
                    }}
                    ref={(ref) => (this.form = ref)}
                    formValue={this.state.initialValue}
                    model={model}
                    layout="horizontal"
                    fluid
                  >
                    <div className="flex-j-c w-100">
                      <div className="login-input flex">
                        <div className="input-icons">
                          <i className="mdi mdi-email-outline"></i>
                        </div>
                        <div className="input-text">
                          <FormGroup className="">
                            <FormControl
                              disabled={step === 2}
                              className=""
                              placeholder="Email Address"
                              name="email"
                              type="email"
                              required
                              onChange={(e) => this.onChange("email", e)}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    {step === 2 ? (
                      <div className="flex-j-c w-100">
                        <div className="login-input flex">
                          <div className="input-icons">
                            <i className="mdi mdi-lock"></i>
                          </div>
                          <div className="input-text">
                            <FormGroup className="">
                              <FormControl
                                className=""
                                placeholder="Verify Code"
                                name="verifyCode"
                                type="text"
                                required
                                onChange={(e) => this.onChange("verifyCode", e)}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="login-button-text">
                      {!loadingButtom ? (
                        <div className="flex-j-c">
                          {step === 2 ? (
                            <>
                              <div className="login-btn">
                                <button onClick={this.handleSubmitVerifyEmail}>
                                  Verify Email
                                </button>
                              </div>
                              <div className="login-btn">
                                <button onClick={() => this.nextStep(1)}>
                                  Change Email
                                </button>
                              </div>
                            </>
                          ) : (
                            <div className="login-btn">
                              <button onClick={this.handleSubmitCheckEmail}>
                                Check Email
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        "loading"
                      )}
                    </div>
                  </Form>

                  <div className="register-text">
                    still user?
                    <span>
                      <NavLink to={"/login"}>Login</NavLink>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}
