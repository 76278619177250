/** @format */

import React, { Component, Fragment } from "react";
import {
  Form,
  FormGroup,
  Notification,
  FormControl,
  Schema,
  Loader,
} from "rsuite";
import { account } from "../../../../api/Api";
import Logo from "../../../../assets/svg/logo";

import { NavLink, Redirect } from "react-router-dom";

const { StringType } = Schema.Types;
const model = Schema.Model({
  email: StringType()
    .isEmail("Please enter a valid email address.")
    .isRequired("Email is required."),

  password: StringType().isRequired("Password is required."),
});
export default class gride extends Component {
  state = {
    loading: false,
    initialValue: {
      email: "",
      password: "",
    },
    formError: {},
    redirect: false,
    loadingButtom: false,
  };

  componentDidMount() {}

  onChange = (name, value) => {
    this.setState({
      initialValue: {
        ...this.state.initialValue,
        [name]: value,
      },
    });
  };
  open = (funcName, title, message) => {
    Notification[funcName]({
      title: title,
      description: <div style={{ color: "black" }}>{message}</div>,
    });
  };

  handleSubmit = async () => {
    try {
      if (await !this.form.check()) {
        if (this.state.formError.email) {
          this.open("error", "error", this.state.formError.email);
        }
        if (this.state.formError.password) {
          this.open("error", "error", this.state.formError.password);
        }

        console.error("Form Error");
        return;
      }

      this.setState({ loadingButtom: true });
      this.setState({ loading: true });

      const { data, status } = await account.login(this.state.initialValue);

      if (status === 200) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("personalData", JSON.stringify(data.data));
        this.open("success", "Login", "you are loged in");
        this.setState({ redirect: true });
      }
    } catch (error) {
      console.error(error.response);
      this.open(
        "error",
        "error",
        error.response && error.response.data && error.response.data.message
      );
    }

    this.setState({ loadingButtom: false, loading: false });
  };

  render() {
    const { redirect, loadingButtom, loading } = this.state;
    return (
      <Fragment>
        {redirect ? <Redirect to="/" /> : ""}
        <main>
          {loading ? <Loader backdrop center content="loading" /> : ""}
          <div className="login-page w-100 flex-j-c">
            <div className="login-container w-100 flex-j-s">
              <div className="left-items w-50 flex-j-c">
                <div className="left-items--text-container">
                  <div className="left-items--title flex-j-c">
                    <span>
                      <NavLink to="/">
                        <Logo />
                      </NavLink>
                    </span>
                  </div>
                  <div className="left-items--content">
                    Welcome to TELEPRINT!
                  </div>
                </div>
              </div>
              <div className="right-items w-50 flex-j-c">
                <div className="right-items--container">
                  <div className="login-account-icon flex-j-c w-100">
                    <i className="mdi mdi-account"></i>
                  </div>
                  <div className="right-items--text">
                    Login below to get started.
                  </div>
                  <Form
                    onCheck={(formError) => {
                      this.setState({ formError });
                    }}
                    ref={(ref) => (this.form = ref)}
                    formValue={this.state.initialValue}
                    model={model}
                    layout="horizontal"
                    fluid
                  >
                    <div className="flex-j-c w-100">
                      <div className="login-input flex">
                        <div className="input-icons">
                          <i className="mdi mdi-email-outline"></i>
                        </div>
                        <div className="input-text">
                          <FormGroup className="">
                            <FormControl
                              className=""
                              placeholder="Email Address"
                              name="email"
                              type="email"
                              required
                              onChange={(e) => this.onChange("email", e)}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>

                    <div className="flex-j-c w-100">
                      <div className="login-input flex">
                        <div className="input-icons">
                          <i className="mdi mdi-lock"></i>
                        </div>
                        <div className="input-text">
                          <FormGroup className="">
                            <FormControl
                              className=""
                              placeholder="Password"
                              name="password"
                              type="password"
                              required
                              onChange={(e) => this.onChange("password", e)}
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>

                    <div className="login-button-text">
                      {!loadingButtom ? (
                        <div className="flex-j-c">
                          <div className="login-btn">
                            <button onClick={this.handleSubmit}>Login</button>
                          </div>
                        </div>
                      ) : (
                        "loading"
                      )}
                    </div>
                  </Form>

                  <div className="register-text">
                    New user?
                    <span>
                      <NavLink to={"/register"}>Register</NavLink>{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}
