import React from "react";

import Header from "../MainHeader";
import Footer from "../MainFooter";

// import { FlexboxGrid } from "rsuite";

function MenuLayout(WrapperComponent) {
  return function (props) {
    return (
      <>
        <Header />
        <WrapperComponent {...props} />
        <Footer />
      </>
    );
  };
}

export default MenuLayout;
