import { Notification } from "rsuite";
export default function notif(text) {
  Notification.open({
    title: "Message",
    duration: 10000,
    description: (
      <div>
        <p>{text}</p>
      </div>
    ),
  });
}
