import * as type from "./actionTypes";
import store from "../store/store";
import { loadState, saveState } from "../utils/localStorage";

function addCart(item) {
  const { data } = store.getState();
  let object = {};
  let reduxCart = data.addCart;
  let cartIndex = reduxCart.findIndex((x) => {
    return x.productPricingId === item.price.id;
  });

  if (cartIndex === -1) {
    object = {
      productPricingId: item.price.id,
      quantity: 1,
    };
    reduxCart.push(object);
  } else {
    reduxCart[cartIndex].quantity += 1;
  }
  return { type: type.ADD_CART, value: reduxCart };
}

function removeCart(id) {
  const { data } = store.getState();
  let reduxCart = data.addCart;
  let cart = [...reduxCart];
  let cartIndex = cart.findIndex((x) => x.id === id);
  cart.splice(cartIndex, 1);
  return { type: type.REMOVE_CART, value: cart };
}

function getCart() {
  let arrayCart = [];
  if (loadState()) {
    let { localData } = loadState();
    localData.addCart.forEach((element) => {
      arrayCart.push(element);
    });
  }
  return { type: type.GET_CART, value: arrayCart };
}

function updateCart(cart) {
  saveState({
    localData: { addCart: cart },
  });
  let newCart = [...cart];
  return { type: type.UBDATE_CART, value: newCart };
}

function reduce(product) {
  const { data } = store.getState();
  let reduxCart = data.addCart;
  let cart = [...reduxCart];
  let thisProductOfCart = cart.find((x) => x.id === product.id);
  if (thisProductOfCart && thisProductOfCart.count > 1) {
    --thisProductOfCart.count;
    return { type: type.REDUCE_CART, value: cart };
  } else {
    return removeCart(product.id);
  }
}

export { addCart, removeCart, getCart, updateCart, reduce };
