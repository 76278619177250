import React from "react";
import "../css/svg.css";
const Svg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    space="preserve"
    width="100%"
    height="100%"
    version="1.0"
    viewBox="0 0 254.96 53.97"
    xlink="http://www.w3.org/1999/xlink"
    xodm="http://www.corel.com/coreldraw/odm/2003"
  >
    <defs></defs>
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer"></metadata>
      <path
        class="fil0 svg-elem-1"
        d="M175.26 39.54c0,1.23 -0.7,2.28 -2.04,3.12 -1.22,0.74 -2.46,1.12 -3.78,1.12l-10.13 0c-2.69,0 -4.03,-1.12 -4.03,-3.34 0,-0.57 0.1,-1.18 0.35,-1.82l2.98 -8.61 -5.64 0 -2.94 7.59c-0.5,1.29 -1.56,1.94 -3.21,1.94l-2.79 0 3.46 -9.53c-1.11,-0.26 -1.99,-0.81 -2.66,-1.66 -0.67,-0.86 -1.03,-1.85 -1.03,-2.95 0,-2.04 0.95,-3.62 2.84,-4.73 1.57,-0.91 3.44,-1.36 5.61,-1.36l4.29 0 -2.22 6.46 14.66 0 -4.84 13.77 11.12 0z"
      ></path>
      <path
        class="fil0 svg-elem-2"
        d="M231.72 39.54c-0.46,1.55 -1.47,2.7 -3.05,3.44 -1.11,0.54 -2.3,0.8 -3.57,0.8l-10.57 0c-2.43,0 -3.62,-0.8 -3.62,-2.39 0,-0.53 0.13,-1.15 0.36,-1.85l2.02 -5.7 -9.13 7.71c-2.12,1.49 -3.88,2.23 -5.25,2.23l-4.88 0 6.33 -18.01 9.57 0 -3 8.47 8.06 -6.62c2.2,-1.78 4.58,-2.64 7.14,-2.57 1.73,0.07 2.58,0.99 2.58,2.75 0,0.66 -0.13,1.39 -0.41,2.21l-3.41 9.53 10.83 0z"
      ></path>
      <path
        class="fil0 svg-elem-3"
        d="M254.96 39.54c-0.83,2.82 -3.05,4.24 -6.64,4.24l-13.5 0c-2.61,0 -3.93,-0.92 -3.93,-2.76 0,-0.46 0.11,-0.95 0.31,-1.48l3.37 -9.53 -4.35 0 1.66 -4.24c3.1,-0.8 5.37,-1.81 6.87,-3.05 1.71,-1.39 3.52,-3.93 5.46,-7.57l5.14 0 -3.72 10.62 6.83 0c-1.09,2.82 -3.31,4.24 -6.67,4.24l-1.76 0 -3.31 9.53 14.24 0z"
      ></path>
      <path
        class="fil0 svg-elem-4"
        d="M130.02 39.54c1.57,-0.45 2.99,-1.4 4.19,-2.89 1.18,-1.43 1.83,-2.86 1.88,-4.27 0.13,-3.17 -1.37,-5.2 -4.52,-6.06 -1.3,-0.37 -3.91,-0.55 -7.89,-0.55l2.2 -6.41 -27.07 0 0 3.7 16.21 0 -10.68 30.91 9.62 0 8.28 -23.96c1.1,-0.04 2.11,0.31 2.99,1.06 0.91,0.74 1.37,1.59 1.37,2.56 0,1.58 -0.41,2.86 -1.24,3.87 -0.59,0.76 -1.47,1.4 -2.64,1.94 -0.82,0.32 -1.65,0.64 -2.48,0.98 0.08,2.23 1.19,3.36 3.36,3.36l14.43 0c1.53,0 2.87,-0.34 4.01,-1.04 1.27,-0.77 1.99,-1.84 2.14,-3.2l-14.16 0z"
      ></path>
      <g id="_2328508580736">
        <polygon
          class="fil1 svg-elem-5"
          points="19.78,10.82 19.78,37.68 8.58,37.68 8.58,10.82 0,10.82 0,0 28.33,0 28.33,10.82 "
        ></polygon>
        <path
          class="fil1 svg-elem-6"
          d="M52.59 14.66l0 8.4 -7.32 0c-1.01,0 -1.71,0.15 -2.12,0.44 -0.42,0.29 -0.62,0.78 -0.62,1.48 0,0.79 0.2,1.31 0.62,1.56 0.44,0.25 1.29,0.37 2.61,0.37l7.55 0 0 10.77 -8.82 0c-4.13,0 -7.31,-0.99 -9.49,-2.97 -2.19,-1.97 -3.28,-4.83 -3.28,-8.57l0 -14.77c0,-3.61 1.04,-6.4 3.08,-8.4 2.09,-1.98 4.99,-2.97 8.74,-2.97l9.77 0 0 10.82 -7.16 0c-1.94,0 -2.9,0.63 -2.9,1.88 0,0.74 0.21,1.25 0.67,1.53 0.47,0.29 1.3,0.43 2.46,0.43l6.21 0z"
        ></path>
        <path
          class="fil1 svg-elem-7"
          d="M74.33 26.75c-1.66,0 -2.77,-0.29 -3.31,-0.87 -0.57,-0.58 -0.85,-1.75 -0.85,-3.49l0 -22.39 -11.2 0 0 24.18c0,4.24 1.14,7.54 3.41,9.93 2.28,2.39 5.46,3.57 9.57,3.57l2.97 0 0 -10.93 -0.59 0z"
        ></path>
        <path
          class="fil1 svg-elem-8"
          d="M98.81 14.66l-6.2 0c-1.17,0 -1.99,-0.14 -2.46,-0.43 -0.47,-0.28 -0.7,-0.79 -0.7,-1.53 0,-1.25 0.98,-1.88 2.92,-1.88l7.14 0 0 -10.82 -9.75 0c-3.75,0 -6.67,0.99 -8.74,2.97 -2.06,2 -3.1,4.79 -3.1,8.4l0 14.77c0,3.74 1.11,6.6 3.31,8.57 2.17,1.98 5.35,2.97 9.49,2.97l8.79 0 0 -10.77 -7.53 0c-1.31,0 -2.17,-0.12 -2.61,-0.37 -0.41,-0.25 -0.62,-0.77 -0.62,-1.56 0,-0.7 0.21,-1.19 0.62,-1.48 0.42,-0.29 1.12,-0.44 2.1,-0.44l7.34 0 0 -8.4z"
        ></path>
        <path
          class="fil1 svg-elem-9"
          d="M195.42 39.54c-0.43,1.55 -1.39,2.7 -2.84,3.44 -1.03,0.54 -2.17,0.8 -3.39,0.8l-10.47 0c-2.53,0 -3.8,-1.07 -3.8,-3.19 0,-0.6 0.13,-1.23 0.39,-1.92l4.6 -12.9 9.62 0 -4.92 13.77 10.81 0z"
        ></path>
        <path
          class="fil2 svg-elem-10"
          d="M188.93 16.59c0.78,-0.28 1.24,-0.61 1.4,-0.99l0.85 1c-0.77,0.79 -1.57,1.36 -2.38,1.72 -0.8,0.37 -1.65,0.56 -2.58,0.56 -0.93,0 -1.78,-0.19 -2.59,-0.56 -0.8,-0.36 -1.58,-0.93 -2.38,-1.72l0.86 -1c0.12,0.38 0.59,0.71 1.4,0.99 0.77,0.29 1.68,0.43 2.71,0.43 1.01,0 1.91,-0.14 2.71,-0.43zm-2.69 4.8c1.95,0 3.58,-0.63 4.94,-1.85 1.37,-1.24 2.07,-2.75 2.07,-4.51 0,-1.75 -0.7,-3.24 -2.07,-4.47 -1.36,-1.24 -2.99,-1.86 -4.94,-1.86 -1.96,0 -3.61,0.62 -4.99,1.86 -1.37,1.23 -2.04,2.72 -2.04,4.47 0,1.76 0.67,3.27 2.04,4.51 1.38,1.22 3.03,1.85 4.99,1.85zm-0.72 -8.78c0,0.42 -0.1,0.75 -0.33,1.01 -0.21,0.25 -0.49,0.39 -0.86,0.39 -0.36,0 -0.65,-0.14 -0.88,-0.39 -0.2,-0.26 -0.31,-0.59 -0.31,-1.01 0,-0.41 0.11,-0.75 0.31,-1.01 0.23,-0.25 0.52,-0.38 0.88,-0.38 0.37,0 0.65,0.13 0.86,0.38 0.23,0.26 0.33,0.6 0.33,1.01zm2.53 1.4c-0.36,0 -0.64,-0.14 -0.87,-0.39 -0.21,-0.26 -0.32,-0.59 -0.32,-1.01 0,-0.41 0.11,-0.75 0.32,-1.01 0.23,-0.25 0.51,-0.38 0.87,-0.38 0.37,0 0.65,0.13 0.86,0.38 0.23,0.26 0.33,0.6 0.33,1.01 0,0.42 -0.1,0.75 -0.33,1.01 -0.21,0.25 -0.49,0.39 -0.86,0.39z"
        ></path>
      </g>
    </g>
  </svg>
);

export default Svg;
